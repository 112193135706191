import React,{useEffect, useContext, useState} from 'react'
import Card from 'react-bootstrap/Card';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function LandingPage() {
  const { loggedUser, validateLogin, churchMaster } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);

  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      setLoaded(false);
      validateLogin();
      setLoaded(true);
    }
  },[loggedUser])  

  return (
    <div className="App">
    <Helmet>
      <title>Admin Office - St. Mary's Church, Madurai</title>
      <meta name="description" content="Admin Office - St. Mary's Church, Madurai" />
      <meta name="keywords" content="Admin Office - St. Mary's Church, Madurai" /> 
    </Helmet>
        <Card>
            <Card.Body>
                <h3 className='text-info' style={{fontFamily:'sans-serif'}}>WELCOME <br/>{churchMaster.chruchFullName}</h3>
                <img className='img-responsive' src={`${config.s3.imageURL}${churchMaster.chruchName}${"/mastar/images/"}${churchMaster.chruchName}`+".jpeg"} alt='logo' width="500px" style={{borderRadius:20}}/>
                <h1>Admin Portal</h1>
            </Card.Body>
        </Card>
    </div>
  )
}
