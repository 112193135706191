import React,{ useState, useContext, useEffect } from "react";
import {Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { Col, Row, Form, Button, Container, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Preloader from "../../components/Preloader/Preloader";
import {Helmet} from 'react-helmet-async';
import { updatePassword } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import { RoutesLocal } from "../../routes";
import { ProductContext } from "../../ProductProvider";

export default function ResetPassword() {
  const { loggedUser, validateLogin } = useContext(ProductContext)
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loaded, setLoaded] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
}, [loggedUser]);

  const handleChangePassword = async(event) =>{
    event.preventDefault();
    try{
        if(oldPassword==="" || newPassword===""){
          enqueueSnackbar("Enter the password!",{variant:'info'});
        }else{
          setLoaded(false);
          await updatePassword({ oldPassword, newPassword });
          setOldPassword('');
          setNewPassword('');
          enqueueSnackbar("Password Updated Successfully!",{variant:'success'});
          setLoaded(true);
        }
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
   }
}

  return (
    <main>
    <Helmet>
      <title>Change Password - Admin Office - St. Mary's Church, Madurai</title>
      <meta name="description" content="Change Password - Admin Office - St. Mary's Church, Madurai" />
      <meta name="keywords" content="Change Password - Admin Office - St. Mary's Church, Madurai" /> 
    </Helmet>
    <div className="py-4">
             <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <BreadcrumbItem>
      <Link to={RoutesLocal.DashboardOverview.path}>
      <i className="fa fa-home" aria-hidden="true"></i>
        </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Change Password
    </BreadcrumbItem>
  </Breadcrumb>

      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            {/*<p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
              </Card.Link>
  </p>*/}
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Change password</h3>
                <Form>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      </InputGroup.Text>
                      <Form.Control type="email" value={loggedUser.email} disabled/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Current Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                      <i className="fa fa-unlock" aria-hidden="true"></i>
                      </InputGroup.Text>
                      <Form.Control required autoFocus type="password" value={oldPassword} 
                      onChange={(event)=>{setOldPassword(event.target.value)}}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                      <i className="fa fa-unlock" aria-hidden="true"></i>
                      </InputGroup.Text>
                      <Form.Control required type="password" value={newPassword} 
                      onChange={(event)=>{setNewPassword(event.target.value)}}/>
                    </InputGroup>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleChangePassword(event)}}>
                    Change password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      </div>
    </main>
  );
};
