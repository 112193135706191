import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from 'react-bootstrap';
import {Modal } from "react-bootstrap";
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { fetchAuthSession } from 'aws-amplify/auth';
import { CSVLink } from "react-csv";
import { downloadSacrementHeaders } from "../../ProData";
import {Helmet} from 'react-helmet-async';
//import FamilyCardForm from "../registration/FamilyCardForm";
//import FamilySacrementsForm from "../registration/FamilySacrementsForm";
//import SubscriberForm from "./SubscriberForm";
//import PaymentForm from "../payment/PaymentForm";
import { useSnackbar } from 'notistack';

const config = require('../../config.json');

export default function SacrementSearch() {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, familyCardFormType, setSacrementsFormType, setFamilySacrements, churchMaster} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('MemberID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [displayResults, setDisplayResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      //processBatchYear();
    }else{
    validateLogin();
    }
    return()=>{
      setDisplayResults([]);
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=(event, item, action)=>{
    event.preventDefault();
    try{
      setSacrementsFormType(action);
      setFamilySacrements(item);
      toggle();
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }

  {/*const handlePaymentForm=(event, item, action)=>{
    event.preventDefault();
    try{
        setFamilyCardFormType(action);
      setCardDetails(item);
      newToggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }

  }*/}

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant : 'info'});
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        var tsearchValue=searchValue
        if(searchTopic==="SubscriberName"){
          tsearchValue=searchValue.toUpperCase();
          setSearchValue(tsearchValue);
        }
        const clientID=loggedUser.familyName;
      await axios.get(`${config.api.invokeUrl}/dashboard/family_sacrements?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            //setSearchResults(response.data);
            const tempData=response.data;
            const result = tempData.sort((a, b) =>a.memberID.localeCompare(b.memberID));
            setDisplayResults(result);

            //setDisplayResults(response.data);
            //processResults(response.data, viewOption, isActiveView);
        }
        enqueueSnackbar(response.data.length + " - Family card records found!",{variant : 'success'});
        setLoaded(true);

    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  })
}
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
    }
  }

  {/*const processResults=(rawData, type, activeView)=>{
    //const rawData=searchResults;
    var tempDisplayResults=[];
    if(type==="Email"){
      const filteredData=rawData.filter(item=>item.emailID!=="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Email"
    });  

      //console.log("filtered data : ", filteredData);
    }else if(type==="No Email"){
      const filteredData=rawData.filter(item=>item.emailID==="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by No Email Address!"
    });  
      //console.log("filtered data : ", filteredData);
    }else{
      tempDisplayResults=rawData;
      //setDisplayResults(rawData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: rawData.length + " - Subscriber's records!"
    });  
    }
    if(activeView){
      const filteredData=tempDisplayResults.filter(item=>item.subStatus==="Active");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Active Accounts"
      });  
    }
    setDisplayResults(tempDisplayResults);
  }*/}

  const handleSearchTopic=(event)=>{
    event.preventDefault();
    setSearchTopic(event.target.value);
    if(event.target.value==="MemberID"){
      setSearchValue('');
    }else{
      setSearchValue("Yes");
    }
  }

  return (
<>
    <Helmet>
      <title>Holy Sacrements Search - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Holy Sacrements Search - Church Admin Office" />
      <meta name="keywords" content="Holy Sacrements Search - Church Admin Office" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        {/*View*/}
        <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div  ref={componentModelRef}>
             <Modal.Header  closeButton>
               Registration Form - {familyCardFormType}
                    </Modal.Header>
                    <Modal.Body >    
                        {/*<FamilySacrementsForm handleSearch={handleSearch} toggle={toggle} /> */}
                    </Modal.Body>
                    <Modal.Footer>
            {' '}
            <ReactToPrint
        content={reactToPrintContentModal}
        documentTitle="AwesomeFileName"
        
        trigger={reactToPrintTriggerModal}
      />
            <Button onClick={toggle}>
                Close
            </Button>
            </Modal.Footer>
          </div>
                   
        </Modal>
        </div>

        {/*Payment*/}
        {/*<Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Payment - Add
            </ModalHeader>
            <ModalBody>    
            <div>
            <PaymentForm formType={"New"} newToggle={newToggle}/> 
              </div>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
          </Modal>*/}


      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i> </Breadcrumb.Item>
            <Breadcrumb.Item>Holy Sacrements</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Search Holy Sacrements</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="student"
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadSacrementHeaders}
              filename="download.csv"
              data={displayResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
        </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="MemberID" value={searchTopic} onChange={(event)=>{handleSearchTopic(event)}}>
                <option value="MemberID">Member ID</option>
                <option value="IsBaptized">Baptism</option>
                <option value="IsEucharist">Eucharist</option>
                <option value="IsConfirmation">Confirmation</option>
                  <option value="IsMarried">Married</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
              <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
              {searchTopic==="MemberID"? 
              <Form.Control type="text"  autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>:          
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
            </Form.Select>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

     <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Member ID</th>
                <th>Name</th>
                <th>Baptism</th>
                <th>Eucharist</th>
                <th>Confirmation</th>
                <th>Mariage</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.memberID}</td>
            <td>{item.memberName}</td>
            <td>{item.isBaptised==='Yes'?'Yes':'No'}</td>
            <td>{item.isEucharist==='Yes'?'Yes':'No'}</td>
            <td>{item.isConfirmation==='Yes'?'Yes':'No'}</td>
            <td>{item.isMarried==='Yes'?'Yes':'No'}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
              <i className="fa fa-pencil" aria-hidden="true"></i> Edit Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>
    </>
  )
}
