import React,{useContext} from 'react';
import { Button, Table, ButtonGroup, Dropdown } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';


export default function ListFamilySacrements(props) {

    const {toggleSacrementForm, displaySacrements, familyCardFormType} = props;
    const { setFamilySacrements, setSacrementsFormType } = useContext(ProductContext);

    const handleSacrementsView =(event, item, action)=>{
        event.preventDefault();
        try{
            //console.log("Item : ", item);
            setSacrementsFormType(action);
            setFamilySacrements(item);
            toggleSacrementForm();
        }catch(error){

        }
    }

  return (
    <>
        {/*<Button variant="primary" type="submit" onClick={(event)=>{handleNewProcessing(event)}}>New</Button>*/}
        <div>List Family Sacrements</div>
        <Table hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Baptism</th>
                <th>Eucharist</th>
                <th>Confirmation</th>
                <th>Mariage</th>
                <th>Death</th>
               {familyCardFormType==="View"?null: <th>Action</th>}
              </tr>
            </thead>
            <tbody>
            {displaySacrements.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.title}. {item.memberName}</td>
            <td>{item.isBaptised==='Yes'?'Yes':'No'}</td>
            <td>{item.isEucharist==='Yes'?'Yes':'No'}</td>
            <td>{item.isConfirmation==='Yes'?'Yes':'No'}</td>
            <td>{item.isMarried==='Yes'?'Yes':'No'}</td>
            <td>{item.isDead==='Yes'?'Yes':'No'}</td>
            {familyCardFormType==="View"?null:<td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleSacrementsView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleSacrementsView(event, item, "Edit")}}>
              <i class="fa fa-pencil" aria-hidden="true"></i> Edit Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>}
           
            </tr>)}
            </tbody>
      </Table>
    </>
  )
}
