import React, { useState, useContext, useEffect, useRef } from 'react';
import { Col, Row, Form, Button, InputGroup } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import { familyRelationshipData } from '../../ProData';
import Preloader from "../../components/Preloader/Preloader";
import { Card  } from "react-bootstrap";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { fetchAuthSession } from 'aws-amplify/auth';
//import { payOptionsData } from '../../ProData';
const config = require('../../config.json');

export default function GraveYardForm(props) {
    const {formType} = props;
    const {loggedUser, generateOrderNo, cardDetails} = useContext(ProductContext);
    const [graveyardDetails,setGraveyardDetails] = useState({PersonName:'', relationship:'', dateOfDeath:'', causeOfDeath:'', placeOfBurial:'', dateOfBurial:'', 
    blessedBy:'', updatedOn:'', updatedBy:'', contactNo:'', graveYardNo:''});
    const [loaded, setLoaded]=useState(true);    
    const [viewOnly, setViewOnly] = useState(true);
    const inputYears=useRef(null);
    const inputPaymentMode=useRef(null);
    const inputEndDate=useRef(null);
    const inputStartDate=useRef(null);
    const inputOrderAmount=useRef(null);
    const inputTransactionDate=useRef(null);
    const inputComments=useRef(null);
    const inputReferenceID=useRef(null);
    const inputCurrency=useRef(null);
    const inputBankName=useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    
    useEffect(()=>{
        //console.log("Props : ", props);
        if(formType==="New"){
            resetValues();
            //assignNewSubcriptionDates();
            //const orderNo=generateOrderNo();
            setViewOnly(false);
            handleYearChange(1);
        }else if (formType==="View"){
            setViewOnly(true);
            assignValues();
        }else if(formType==="Edit"){
            setViewOnly(false);
            assignValues();
        }
        //inputStartDate.current.focus();
        //initializeValues();
        //setEnrollCourse(selectedItem.selectedCourse);
        //setFinalAmount(selectedOptionDetails.courseAmount);
    },[])

    function generateDate() {
        const current_datetime = new Date();
        var tempUserId = "";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value = "";
        date_month.length === 1 ? (month_value = month_value.concat(0, date_month)) : (month_value = date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value = "";
        date_date.length === 1 ? (date_value = date_value.toString().concat(0, date_date)) : (date_value = date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value = "";
        date_hours.length === 1 ? (hours_value = hours_value.toString().concat(0, date_hours)) : (hours_value = date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value = "";
        date_minutes.length === 1 ? (minutes_value = minutes_value.toString().concat(0, date_minutes)) : (minutes_value = date_minutes);
        const accountNo = tempUserId.concat(date_year, "-", month_value, "-", date_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
    }

    const resetValues=()=>{
        const todayDate= generateDate();
        setGraveyardDetails({...graveyardDetails, PersonName:'', relationship:'', dateOfDeath:'', causeOfDeath:'', placeOfBurial:'', dateOfBurial:'', 
            blessedBy:'', updatedOn:'', updatedBy:'', contactNo:'', graveYardNo:''});
    }

    const validateFields=()=>{
        var validateResult="PASS";
            if(graveyardDetails.PersonName===""){
                enqueueSnackbar("Enter Person Name!",{variant : 'info'});
                validateResult="FAIL";
            }else if(graveyardDetails.relationship===""){
                enqueueSnackbar("Select the relationship!",{variant : 'info'});
                validateResult="FAIL";
        }else if(graveyardDetails.dateOfDeath===""){
            enqueueSnackbar("Enter Date of Death!",{variant : 'info'});
                validateResult="FAIL";
            }else if(graveyardDetails.causeOfDeath===""){
                enqueueSnackbar("Enter cause Of Death!",{variant : 'info'});
                validateResult="FAIL";
            }else if(graveyardDetails.dateOfBurial===""){
                enqueueSnackbar("Enter date of Burial!",{variant : 'info'});
                validateResult="FAIL";
            }else if(graveyardDetails.blessedBy===""){
                enqueueSnackbar("Enter Blessed By Name!",{variant : 'info'});
            validateResult="FAIL";
        }else if(cardDetails.clientID===""||cardDetails.familyID===""){
            enqueueSnackbar("Refresh the page and search with valid family ID!! ",{variant : 'info'});
            validateResult="FAIL";
        }else{
            validateResult="PASS";
        }
        return validateResult;
    }

    const assignNewSubcriptionDates=()=>{
        var tempDate= new Date(cardDetails.endDate);
        tempDate.setDate(tempDate.getDate() + 1);
        var date_date = ((tempDate.getDate()).toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_month = (tempDate.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        const tempYear = (tempDate.getFullYear()).toString();
        var tempRenew = "";
        const newStartDate = tempRenew.concat(tempYear,"-",month_value,"-",date_value);
        const tempendDate= new Date(cardDetails.endDate);
        var date_date1 = ((tempendDate.getDate()).toString());
        var date_value1="";
        date_date1.length===1?(date_value1=date_value1.toString().concat(0,date_date1)):(date_value1=date_date1);
        var date_month1 = (tempendDate.getMonth() + 1).toString();
        var month_value1="";
        date_month1.length===1? (month_value1=month_value1.concat(0,date_month1)):(month_value1=date_month1);
        const tempYear1 = (tempendDate.getFullYear()+1).toString();
        var tempRenew1 = "";
        const newEndDate = tempRenew1.concat(tempYear1,"-",month_value1,"-",date_value1);

        //setSubscribedAmount(amount);
        //setFinalAmount(amount);
        //setcardDetails({...cardDetails, endDate:renewDate});
        setGraveyardDetails({...graveyardDetails, 
            years: 1,
            packageAmount:cardDetails.subscription,
            orderAmount:250,
            startDate:newStartDate,
            endDate:newEndDate
        });
    }

    const assignValues=()=>{
        setGraveyardDetails(props.selectedItem);
        //console.log("props : ", props);
    }
    
    const handleSave=async(event)=>{
        event.preventDefault();
        try{
            const validateResult=await validateFields();
            //console.log("validation : ", validateResult);
            if(validateResult==="PASS"){
            //setLoaded(false);
            const tdate=generateDate();
            const graveYardID=generateOrderNo();
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID=loggedUser.familyName;
            const {PersonName, relationship, dateOfDeath, causeOfDeath, dateOfBurial, 
            blessedBy }   = graveyardDetails;         
            const params={
                clientID:clientID,
                graveYardID:graveYardID,
                PersonName:PersonName,
                relationship:relationship,
                dateOfDeath:dateOfDeath,
                causeOfDeath:causeOfDeath,
                dateOfBurial:dateOfBurial,
                blessedBy:blessedBy,
                updatedBy:loggedUser.name,
                updatedOn:tdate,
                graveYardNo:cardDetails.graveYardNo,
                familyID:cardDetails.familyID,
                contactNo:'999999999'
            };

            console.log("Params : ", params);
            await axios.post(`${config.api.invokeUrl}/dashboard/family-graveyard`, params,{
                headers: {
                  Authorization: idToken,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  //console.log("response : ", response); 
                 //fetchCurrentYearDetails(selectedBatch);
                enqueueSnackbar("Graveyard details successfully Added! ",{variant : 'success'});
                resetValues();
                window.scrollTo(0,0); 
                setLoaded(true);
                //newToggle();
                }).catch((error)=>{
                    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
                    window.scrollTo(0,0); 
                  setLoaded(true);
                })
            }
    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);      
    }
    }

    const handlePaymentEdit=async(event)=>{
        event.preventDefault();
        try{
            const validateResult=validateFields();
            //console.log("validation : ", validateResult);
            if(validateResult==="PASS"){
            setLoaded(false);
            const tdate=generateDate();
            //const paymentid=generateOrderNo();
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID=loggedUser.familyName;
            const {PersonName, relationship, dateOfDeath, causeOfDeath, dateOfBurial, 
            blessedBy, graveYardID, graveYardNo, familyId }   = graveyardDetails;         
            const params={
                clientID:clientID,
                graveYardID:graveYardID,
                personName:PersonName,
                relationship:relationship,
                dateOfDeath:dateOfDeath,
                causeOfDeath:causeOfDeath,
                dateOfBurial:dateOfBurial,
                blessedBy:blessedBy,
                updatedBy:loggedUser.name,
                updatedOn:tdate,
                graveYardNo:graveYardNo,
                familyId:familyId
            };

            console.log("Params : ", params);
            //const paymentid=paymentID;
            await axios.patch(`${config.api.invokeUrl}/dashboard/family-graveyard`, params,{
                headers: {
                  Authorization: idToken,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  //console.log("response : ", response); 
                 //fetchCurrentYearDetails(selectedBatch);
                 enqueueSnackbar("Graveyard details successfully Updated! ",{variant : 'success'});
                resetValues();
                window.scrollTo(0,0); 
                setLoaded(true);
                props.newToggle();
                }).catch((error)=>{
                    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
                    window.scrollTo(0,0); 
                  setLoaded(true);
                })
            }

    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);      
    }
    }

    const handleYearChange =async(months)=>{
        var amount=months*cardDetails.subscription;
        //const tempDate=  new Date(cardDetails.lastSubscriptionDate);

        const rolloverYear = new Date(cardDetails.lastSubscriptionDate);
        rolloverYear.setMonth(rolloverYear.getMonth() + parseInt(months));
        rolloverYear.toISOString();
       // console.log("roll : ", rolloverYear);

//        const newDate=new Date(tempDate.getFullYear(),tempDate.getMonth(), tempDate.getDate());
//        newDate.setMonth(newDate.getMonth()+parseInt(months));
//        console.log("New dAte : ", newDate);

        const tempYear = (rolloverYear.getFullYear()).toString();
        var date_month = (rolloverYear.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):date_month>12?(month_value=date_month):(month_value=date_month);
        var date_date = (rolloverYear.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var tempRenew = "";
        const renewDate = tempRenew.concat(tempYear,"-",month_value,"-",date_value);
        await setGraveyardDetails({...graveyardDetails, 
            months: months,
            packageAmount:cardDetails.subscription,
            orderAmount:amount,
            endDate:renewDate
        });
    }

    const handleEventKey=(event, item)=>{
        //console.log("event : ", event);
        if(event.key === 'Enter'){
            if(item==="1"){
                inputEndDate.current.focus();
            }else if(item==="2"){
                inputPaymentMode.current.focus();
            }else if(item==="3"){
                inputCurrency.current.focus();
            }else if(item==="4"){
                inputYears.current.focus();
            }else if(item==="5"){
                inputOrderAmount.current.focus();
            }else if(item==="8"){
                inputTransactionDate.current.focus();
            }else if(item==="9"){
                inputComments.current.focus();
            }
        }
    }

  return (
    <div>  
    <Preloader show={loaded ? false : true} />
    <div className="table-settings mb-4">
    {formType==="View" || formType==="Edit"?null : <><Row>
    <Col md={6} className="mb-3">
    <Form.Label>Family ID : {cardDetails.familyID} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Graveyard : {cardDetails.graveYardNo} </Form.Label>
    </Col>
    </Row>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Zone : {cardDetails.familyAnbiyam} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Family Head : {cardDetails.title}.{cardDetails.familyHead} </Form.Label>
    </Col>
  </Row></>}    
    <div  style={{paddingTop:"20px"}}>
        <Card>
        <Card.Body>
        <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Name of the Person :  </Form.Label>
     <Form.Group id="searchstud">
     <Form.Control type="text" placeholder="Person Name" value={graveyardDetails.PersonName} disabled={viewOnly}
            ref={inputBankName} onKeyDown={event=>{handleEventKey(event,"7")}}
          onChange={(event)=>{setGraveyardDetails({...graveyardDetails, PersonName: event.target.value})}}/>
         </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
    <Form.Label>Relationship:  </Form.Label>
    <Form.Group id="currency">
           
    <Form.Select defaultValue="INR" value={graveyardDetails.relationship} disabled={viewOnly}
           onChange={(event)=>{setGraveyardDetails({...graveyardDetails, relationship: event.target.value})}}
           ref={inputCurrency} onKeyDown={event=>{handleEventKey(event,"4")}} >
                {familyRelationshipData.map(item => {
                    return (
                    <option key={item.name} value={item.name}>{item.name}</option>
                    );
                })}
           </Form.Select>
         </Form.Group>
        </Col>
        </Row>
        <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Date Of Death:  </Form.Label>
    <Form.Group id="searchstud">
    <InputGroup>
         <Form.Control type="date" value={graveyardDetails.dateOfDeath} disabled={viewOnly}
         onChange={(event)=>{setGraveyardDetails({...graveyardDetails, dateOfDeath: event.target.value})}}
         onKeyDown={event=>{handleEventKey(event,"9")}} ref={inputTransactionDate}/>
       </InputGroup> 
        </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
    <Form.Label>Date Of Burial:  </Form.Label>
    <Form.Group id="searchstud">
    <InputGroup>
         <Form.Control type="date" value={graveyardDetails.dateOfBurial} disabled={viewOnly}
         onChange={(event)=>{setGraveyardDetails({...graveyardDetails, dateOfBurial: event.target.value})}}
         onKeyDown={event=>{handleEventKey(event,"9")}} ref={inputTransactionDate}/>
       </InputGroup> 
        </Form.Group>
        </Col>

    </Row> 
     <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Cause Of Death:  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Cause Of Death" value={graveyardDetails.causeOfDeath} 
          disabled={viewOnly} ref={inputReferenceID} onKeyDown={event=>{handleEventKey(event,"6")}}
          onChange={(event)=>{setGraveyardDetails({...graveyardDetails, causeOfDeath: event.target.value})}}/>
        </InputGroup>
    </Col>
    <Col md={6} >
      <Form.Label>Blessed By :  </Form.Label>
    
      <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Blessed By...." value={graveyardDetails.blessedBy} disabled={viewOnly}
            ref={inputBankName} onKeyDown={event=>{handleEventKey(event,"7")}}
          onChange={(event)=>{setGraveyardDetails({...graveyardDetails, blessedBy: event.target.value})}}/>
        </InputGroup>
      </Col>
    </Row>
    </Card.Body>
    </Card>
    </div>
    
    <Row>
    {formType==="New"?<div className="text-center mb-2 mb-md-0">
    <Button variant="primary" type="submit" onClick={(event)=>{handleSave(event)}}>Save</Button>
      </div>:formType==="Edit"?<div className="text-center mb-2 mb-md-0">
    <Button variant="primary" type="submit" onClick={(event)=>{handlePaymentEdit(event)}}>Update</Button>
      </div>:null}
    </Row>
  </div></div>
  )
}
