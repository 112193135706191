import React, {useState, useEffect, useContext} from 'react';
import { Row, Card, Form, Button } from 'react-bootstrap';
import axios from "axios";
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function EmailUpdateModal(props) {
    const { loggedUser, generateDate } = useContext(ProductContext);
    const {selectedRegistrationNo, selectedEmail, fetchCurrentYearDetails, emailToggle} = props;
    const [newEmail, setNewEmail] =useState('');
    const [loaded, setLoaded]=useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        //console.log("Props : " , props);
        if(selectedRegistrationNo){
        setLoaded(true);
    }
    }, [selectedEmail, selectedRegistrationNo])
   
    const handleEmailChange =async(event)=>{
        event.preventDefault();
        try{
            if(newEmail===""){
              enqueueSnackbar("New Email Address Cannot be Empty",{variant : 'info'});
            }else if(!newEmail.match(validRegex)){
              enqueueSnackbar("Email address is not in right format!",{variant : 'info'});
              }else{
            setLoaded(false);
            const todayDate=await generateDate();
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const params={
                registrationNo: selectedRegistrationNo, 
                oldemail:selectedEmail, 
                newemail:newEmail, 
                updatedBy:loggedUser.nickname, 
                updatedOn:todayDate
            };
            //console.log("Params : ", params); 
            await axios.patch(`${config.api.invokeUrl}/subscribers/accounts/emailid`, params, {
                headers: {
                  Authorization: idToken,
                  'x-api-key':config.api.key
                }
              }).then(async(res)=>{
                await fetchCurrentYearDetails();
                emailToggle();     
                enqueueSnackbar("Email Successfully Updated",{variant : 'success'});
                }).catch((error)=>{
                  enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
                    setLoaded(true);   
                })
            }
        }catch(error){
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);              
        }
        setLoaded(true);
    }


  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <Form>
         <Row>
         <Form.Group id="currentEmail">
         <Form.Label>Current Email Address</Form.Label>
        <Form.Control type="text" value={selectedEmail}
            disabled />
            </Form.Group>
         </Row>
         <Row>
         <Form.Group id="newEmail">
        <Form.Label>New Email Address</Form.Label>
        <Form.Control type="text" name="newEmail"
        value={newEmail} onChange={(event)=>{setNewEmail(event.target.value)}}/>
        </Form.Group>
         </Row>
         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{handleEmailChange(event)}}>Update</Button>
           </div>

         </Form>
         </Card.Body>     

    </Card>
    </>
  )
}

