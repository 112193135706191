import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import ImageDisplay from '../../components/manageimage/ImageDisplay';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "react-bootstrap";
import { Button, Dropdown } from 'react-bootstrap';
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';
import AddGraveYardPic from './AddGraveYardPic';
import { useSnackbar } from 'notistack';

//import constIcon from "../../assets/img/const-icon.png";
const config = require('../../config.json');

export default function GraveYardPic(props) {
    const {selectedItem}=props;
    const { validateLogin, alertClose , loggedUser, alertDetails, setAlertDetails, generateDate, churchMaster, handleImageError} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [galleryImages, setGalleryImages] = useState('');
    const [collapsed, setCollapsed] = useState(true);
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState('vjdepth-logo.png');
    const [newFileName, setNewFileName]=useState('');
    const { enqueueSnackbar } = useSnackbar();

    //const [newDoctorDept, setNewDoctorDept]=useState('');
    //const [newProjectStatus, setNewProjectStatus]=useState('');
    //const [newProjectDate, setNewProjectDate]=useState('');


    useEffect(() => {
        if(loggedUser.isAuthenticated){
            fetchGalleryImages();  
        }else{
            validateLogin();
        }
    }, [loggedUser]);

    const handleDeleteImage = async(event, imageName)=>{
        event.preventDefault();
        setLoaded(false);
      
       // const imageid=imageName.substring(12,imageName.length);
       try{
           if(galleryImages.length<2){
            enqueueSnackbar("Delete not allowed! Only one image left in Slider",{variant : 'info'});
           }else{
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
//            console.log("image : ", imageName  );
        await axios.get(`${config.api.invokeUrl}/dashboard/images/remove?imageName=${imageName}`,{
            headers:{
                Authorization: idToken,
                'x-api-key': config.api.key
            }
        }).then((response)=>{
            //console.log(response);
            //setGalleryImages(response.data);
            //setDispMsg("Deleted Successfully");
            enqueueSnackbar("Deleted Successfully",{variant : 'success'});
            window.scrollTo(0,0);
            fetchGalleryImages();
        }).catch((error)=>{
           // setDispMsg(error.message);
           enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }
    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }

    const toggle = () => {
     //   console.log("clicked");
          setCollapsed(!collapsed);
      }

    const fetchGalleryImages =async()=>{
        try{
            setLoaded(false);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
          //const fileName='images/gallery/';
         // const bucketName="images.stjosephhospitaldindigul.in";
      //await axios.get(`${config.api.invokeUrl}/images/gallery?fileName=${keyName}&bucketName=${bucketName}`,{
        //const fileName='images/doctor/';
        const clientID=loggedUser.familyName;
        const fileName='graveyard/'+clientID+"/"+selectedItem.graveYardNo;
        const bucketName="images.chruch.com";
      await axios.get(`${config.api.invokeUrl}/dashboard/family-graveyard/images?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
              Authorization: idToken,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log("Response : ", response);
            const finalList=[];
           // console.log("length:", response.data.length);

            if(response.data.length>0){
              for (const i in response.data){
                const tValue=response.data[i];
                const tPathName=tValue.split("/");
                const tDate= tPathName[3].substring(tPathName[3].indexOf("_")+1,tPathName[3].length-5);
                const tBreakUp=tPathName[2];
                const tempValue={};
                tempValue.fullPathName=response.data[i];
                tempValue.fileName=tBreakUp;
                tempValue.fullDate=tDate;
                finalList.push(tempValue);
              }
            }
      
           // console.log(finalList);
            setGalleryImages(finalList);
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
            enqueueSnackbar(response.data.length + " - Images are found in Slider section",{variant : 'success'});
            setLoaded(true);
            window.scrollTo(0,0); 
        }).catch((error)=>{
            console.log(error);
            //setDispMsg(error.message);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }catch(error){
       // setDispMsg(error.message);
       enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
}

/*const addImageOnS3=async()=>{
    try{ 
        setLoaded(false);
      
        const fileName=projectName+"_"+new Date().getTime();
      const newFileName=fileName+".jpeg";
      const bucketName=config.s3.bucketName+keyName;
      //console.log("bucket name : ", bucketName);
      //console.log("file name : ", newFileName);

      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //console.log("access token : ", access_token);
      await axios.get(`${config.api.invokeURL}/images/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
            // console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                 var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 toggle();

             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }
    setLoaded(true);

}*/

const postOnS3 = async(event)=>{
    event.preventDefault();
    try{
    const todayDate=generateDate();
    const newFileName=selectedItem.graveYardNo +"_"+ todayDate +".jpeg";
    //console.log("New file : ", newFileName);

      //const newFileName=fileName+".jpeg";      
      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
     const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const clientID=loggedUser.familyName;
      //const fileName='graveyard/SMCMDU';
      const fullbucketName="images.chruch.com/graveyard/";

      const bucketName=fullbucketName+clientID+"/"+selectedItem.graveYardNo;
      //console.log("bucketName : ", bucketName);

      await axios.get(`${config.api.invokeUrl}/dashboard/profile_avatar/presigned/uploader?bucketName=${bucketName}&fileName=${newFileName}`,{
           headers:{
               Authorization: idToken,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          //console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             console.log("Res : ", res);
             enqueueSnackbar("Image Uploaded Successfully",{variant : 'success'});
             if(res.status===200){
                setProfileImage(newFileName);
             }
        }).catch((error)=>{
           // console.log("error1:", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'}); 
          //  return "Failed";
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
      //console.log("error2:", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }


    return (
    <div> 
        <Helmet>
        <title>Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Admin Office - Church Admin Office" />
      <meta name="keywords" content="Admin Office - Church Admin Office" /> 
        </Helmet>

        <h3>Grave Yard Images</h3>
        <Preloader show={loaded ? false : true} />

        {/* Add Images */}
        <Modal onHide={toggle} show={!collapsed} size="lg">
        <ModalHeader toggle={toggle}>
            Add images            
        </ModalHeader>
        <ModalBody>
            <AddGraveYardPic setNewFileName={setNewFileName} setImageFile={setImageFile} profileImage={profileImage} alertDetails={alertDetails} alertClose={alertClose} selectedItem={selectedItem}/>
        </ModalBody>
        <ModalFooter>
            <Button
            color="primary"
            onClick={(event)=>postOnS3(event)}
            >
            Add Image
            </Button>
            {' '}
            <Button onClick={toggle}>
            Cancel
            </Button>
        </ModalFooter>
        </Modal>
 
        <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{toggle(event)}} variant="secondary" className="text-dark me-2">
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>New</span>
          </Dropdown.Toggle>
        </Dropdown>
       
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', textAlign:'center'}}>
        {galleryImages.length>0? galleryImages.map((images, index)=><ImageDisplay fullDate={images.fullDate}
        imageSrc={images.fullPathName} doctorName={images.fileName} key={index} handleDeleteImage={handleDeleteImage} handleImageError={handleImageError}/>):
        <div>No Images found in your Image Gallery</div>} 
        </div>

        </div>
    )
}
