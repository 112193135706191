import React, { useState } from 'react';
import { Table, Dropdown, Button, ButtonGroup, Modal } from 'react-bootstrap';
import GraveYardForm from '../graveyard/GraveYardForm';

export default function BurialList(props) {
    const {listResult}= props;
    const [formCollapse, setFormCollapse]=useState(true);
    const [formType, setFormType]=useState('View');
    const [selectedItem, setSelectedItem]=useState();

    const handleApplicationView=(event, item, type)=>{
        event.preventDefault();
        //console.log("Item :", item);
        setFormType(type);
        setSelectedItem(item);
        formToggle();
    }

    const formToggle=()=>{
        setFormCollapse(!formCollapse);
    }

  return (
    <div>
         {/*MemberForm*/}
         <div >
          <Modal onHide={formToggle} show={!formCollapse} size="lg" >
          <div >
             <Modal.Header  closeButton>
               Graveyard Form - {formType}
                  </Modal.Header>
                    <Modal.Body >    
                        <GraveYardForm formType={formType} selectedItem={selectedItem}/> 
                    </Modal.Body>
                    <Modal.Footer>
            {' '}
            <Button onClick={formToggle}>
                Close
            </Button>
            </Modal.Footer>
          </div>      
        </Modal>
        </div>

     <Table hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Family Card ID</th>
                <th>Name</th>
                <th>Relation</th>
                <th>Graveyard No</th>
                <th>Burial Date</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {listResult.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.familyID}</td>
            <td>{item.PersonName}</td>
            <td>{item.relationship}</td>
            <td>{item.graveYardNo}</td>
            <td>{item.dateOfBurial}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i> 
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i>  View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
              <i className="fa fa-pencil" aria-hidden="true"></i>  Edit Details
              </Dropdown.Item>
            </Dropdown.Menu>
           </Dropdown>
           </td>
           </tr>)}
           </tbody>
      </Table>
    </div>
  )
}
