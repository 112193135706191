import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import ImageDisplay from '../../../components/manageimage/ImageDisplay';
import { Modal } from "react-bootstrap";
import { Button, Dropdown } from 'react-bootstrap';
import Preloader from "../../../components/Preloader/Preloader";
import AddSlider from '../AddSlider';
import { ProductContext } from '../../../ProductProvider';
import {Helmet} from 'react-helmet-async';
import logo from '../../../images/logo/logo-new.png';
import { useSnackbar } from 'notistack';

const config = require('../../../config.json');

export default function IssuePoster() {
    const { validateLogin, loggedUser} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [galleryImages, setGalleryImages] = useState('');
    const [collapsed, setCollapsed] = useState(true);

    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState(logo);
    const [newFileName, setNewFileName]=useState('currentPoster');
    //const [newDoctorDept, setNewDoctorDept]=useState('');
    //const [newProjectStatus, setNewProjectStatus]=useState('');
    //const [newProjectDate, setNewProjectDate]=useState('');
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if(loggedUser.isAuthenticated){
            fetchGalleryImages();  
        }else{
            validateLogin();
        }
    }, [loggedUser]);

    const handleDeleteImage = async(event, imageName)=>{
        event.preventDefault();
        setLoaded(false);
       // const imageid=imageName.substring(12,imageName.length);
       try{
           if(galleryImages.length<1){
            enqueueSnackbar("Delete not allowed! Only one image left in Slider",{variant : 'info'});
           }else{
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
        await axios.get(`${config.api.invokeUrl}/images/remove?imageName=${imageName}`,{
            headers:{
                Authorization: idToken,
                'x-api-key': config.api.key
            }
        }).then((response)=>{
            enqueueSnackbar("Deleted Successfully",{variant : 'success'});
            window.scrollTo(0,0);
            toggle();
        }).catch((error)=>{
           enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }
    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }

    const toggle = () => {
     //   console.log("clicked");
          setCollapsed(!collapsed);
      }

    const fetchGalleryImages =async()=>{
        try{
            setLoaded(false);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const fileName='images/poster';
        const bucketName="images.vidyajyotijournal.com";
      await axios.get(`${config.api.invokeUrl}/images/slider?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
              Authorization: idToken,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            const finalList=[];
            if(response.data.length>0){
              for (const i in response.data){
                const tValue=response.data[i];
                const tPathName=tValue.split("/");
                const tBreakUp=tPathName[2];
                const tempValue={};
                tempValue.fullPathName=response.data[i];
                tempValue.fileName=tBreakUp;
                finalList.push(tempValue);
              }
            }
            setGalleryImages(finalList);
            enqueueSnackbar(response.data.length + " - Images are found in Issue Poster section",{variant : 'success'});
            setLoaded(true);
            window.scrollTo(0,0); 
        }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
}

/*const addImageOnS3=async()=>{
    try{ 
        setLoaded(false);
      
        const fileName=projectName+"_"+new Date().getTime();
      const newFileName=fileName+".jpeg";
      const bucketName=config.s3.bucketName+keyName;
      //console.log("bucket name : ", bucketName);
      //console.log("file name : ", newFileName);

      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //console.log("access token : ", access_token);
      await axios.get(`${config.api.invokeURL}/images/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
            // console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                 var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 toggle();

             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }
    setLoaded(true);

}*/

const postOnS3 = async(event)=>{
    event.preventDefault();
    try{ 
        if(imageFile===""||newFileName==="")
        {
            enqueueSnackbar("Enter all the details before Adding!",{variant : 'info'});
            setLoaded(true);
            window.scrollTo(0,0);   

        }else{
        setLoaded(false);
        const fileName=newFileName
     const tnewFileName=fileName+".jpeg";
     const keyName='images/poster';

      const bucketName=config.s3.sliderBucketName+keyName;

      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      await axios.get(`${config.api.invokeUrl}/presigned/uploader?fileName=${tnewFileName}&bucketName=${bucketName}`,{
           headers:{
              Authorization: idToken,
              'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             if(res.status===200){
                fetchGalleryImages();
                setNewFileName(logo);
                 setProfileImage(newFileName);
                 var tprofile = document.getElementById("profile");
                tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 toggle();

             }
        }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
            window.scrollTo(0,0);   
       })
    }
    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0);   
    }
    setLoaded(true);
  }

    return (
        <div> 
    <Helmet>
      <title>Issue Poster - Website - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Issue Poster - Website  - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Issue Poster - Website - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

        <h3>Issue Poster</h3>
        <Preloader show={loaded ? false : true} />
        {/* Add Doctor Images */}
            <Modal onHide={toggle} show={!collapsed} size="lg">
            <Modal.Header toggle={toggle} closeButton>
                Add images            
            </Modal.Header>
            <Modal.Body>
                <AddSlider setNewFileName={setNewFileName} setImageFile={setImageFile} profileImage={profileImage} />
            </Modal.Body>
            <Modal.Footer>
      <Button
        color="primary"
        onClick={(event)=>postOnS3(event)}
      >
        Add Image
      </Button>
      {' '}
      <Button onClick={toggle}>
        Cancel
      </Button>
    </Modal.Footer>
            </Modal>
 
            <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{toggle(event)}} variant="secondary" className="text-dark me-2">
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>
       
            <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', textAlign:'center'}}>
            {galleryImages.length>0? galleryImages.map((images, index)=><ImageDisplay 
            imageSrc={images.fullPathName} doctorName={images.fileName} key={images.fileName} handleDeleteImage={handleDeleteImage}/>):
            <div>No Images found in your issue Poster</div>} 
            </div>

        </div>
    )
}
