import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from 'react-bootstrap';
import {Modal } from "react-bootstrap";
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { fetchAuthSession } from 'aws-amplify/auth';
import { CSVLink } from "react-csv";
import { cardStatusData, downloadCardHeaders } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import FamilyCardForm from "../registration/FamilyCardForm";
import defaultAvatar from '../../images/defaultAvatar.jpg';
//import SubscriberForm from "./SubscriberForm";
import PaymentForm from "../payment/PaymentForm";
import { useSnackbar } from 'notistack';

const config = require('../../config.json');

export default function CardSearch() {
  const { loggedUser, validateLogin, familyCardFormType, setFamilyCardFormType, setCardDetails, displayMembers, setDisplayMembers, churchMaster, setDisplaySacrements} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('FamilyID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  //const [isLabelViewChecked, setIsLabelViewChecked] = useState(false);
  //const [viewOption,setViewOption] = useState("All");
  //const [isActiveView, setIsActiveView] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      //processBatchYear();
    }else{
    validateLogin();
    }
    return()=>{
      setDisplayMembers([]);
      setDisplaySacrements([]);
      setDisplayResults([]);
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=async(event, item, action)=>{
    event.preventDefault();
    try{
      setLoaded(false);
      //console.log("Display Members : ", item);
      if(searchTopic==="FamilyID1"){
        //console.log("Display Members : ", item);
        setFamilyCardFormType(action);
        setCardDetails(item);
      }else{
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const searchTopic="FamilyID";
          const tsearchValue=item.familyID;
          const clientID=loggedUser.familyName;
          await axios.get(`${config.api.invokeUrl}/dashboard/family_card?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            //setDisplayResults(response.data[0]);
            if(searchTopic==="FamilyID"){
              setDisplayMembers(response.data[1]);
              setDisplaySacrements(response.data[2]);
            }
            setCardDetails(item);
            setFamilyCardFormType(action);
        }
        enqueueSnackbar(response.data.length + " - Family card records found!",{variant : 'success'});
        setLoaded(true);
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  })
  }
    toggle();
    setLoaded(true);
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }

  const handlePaymentForm=(event, item, action)=>{
    event.preventDefault();
    try{
        setFamilyCardFormType(action);
      setCardDetails(item);
      newToggle();
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }

  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant : 'info'});
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        var tsearchValue=searchValue
        if(searchTopic==="FamilyID"||searchTopic==="FamilyHead"){
          tsearchValue=searchValue.toUpperCase();
          setSearchValue(tsearchValue);
        }
        const clientID=loggedUser.familyName;
        //console.log("client ID :", clientID);
        await axios.get(`${config.api.invokeUrl}/dashboard/family_card?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            await sortValues(response.data[0]);
            //setDisplayResults(response.data[0]);
            if(searchTopic==="FamilyID"){
              setDisplayMembers(response.data[1]);
              setDisplaySacrements(response.data[2]);
            }
        }
        enqueueSnackbar(response.data[0].length + " - Family card records found!",{variant : 'success'});
        setLoaded(true);
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  })
}
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
    }
  }

     const sortValues=(tempData)=>{
        //console.log("TEmp Data : ", tempData);
        //var sortedOptionArray = tempData.sort((a, b) => {return a.familyAnbiyam - b.familyAnbiyam;});
        const result = tempData.sort((a, b) =>a.familyAnbiyam.localeCompare(b.familyAnbiyam));
        //const result = tempData.sort((a, b) =>a.familyID.localeCompare(b.familyID));
        //console.log("Sorted Data : ", result);
        setDisplayResults(result);
        /*setCourseOptionAvailable(sortedOptionArray);
        setOptedCourse({...optedCourse,
          courseName:sortedcourseArray[0].courseName,
          courseOption:sortedOptionArray[0].courseOption
        });*/
    }


  /*const processResults=(rawData, type, activeView)=>{
    //const rawData=searchResults;
    var tempDisplayResults=[];
    if(type==="Email"){
      const filteredData=rawData.filter(item=>item.emailID!=="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Email"
    });  

      //console.log("filtered data : ", filteredData);
    }else if(type==="No Email"){
      const filteredData=rawData.filter(item=>item.emailID==="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by No Email Address!"
    });  
      //console.log("filtered data : ", filteredData);
    }else{
      tempDisplayResults=rawData;
      //setDisplayResults(rawData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: rawData.length + " - Subscriber's records!"
    });  
    }
    if(activeView){
      const filteredData=tempDisplayResults.filter(item=>item.subStatus==="Active");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Active Accounts"
      });  
    }
    setDisplayResults(tempDisplayResults);
  }*/

 /* const handleLabelViewCheck =()=>{
    //event.preventDefault();
    setIsLabelViewChecked(!isLabelViewChecked);
  }

  const handleViewOptionChange=async(type)=>{
    await processResults(searchResults, type, isActiveView);
    setViewOption(type);

  }

  const handleActiveSwitchChange=async()=>{
    await processResults(searchResults, viewOption, !isActiveView);
    setIsActiveView(!isActiveView);
  }*/

const handleSearchTopicSelection=(event)=>{
  event.preventDefault();
  setSearchTopic(event.target.value);
  if(event.target.value==="FamilyStatus"){
    setSearchValue("Active");
  }else if(event.target.value==="FamilyZone"){
    //console.log("anbiyam : ", churchMaster.chruchAnbiyam[0].anbiyamName);
    setSearchValue(churchMaster.chruchAnbiyam[0].anbiyamName);
  }else{
    setSearchValue();
  }

}

const handleImageError=(e)=>{
  e.target.src = defaultAvatar;
}


return (
<>
    <Helmet>
      <title>Family Card Search - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Family Card Search - Church Admin Office" />
      <meta name="keywords" content="Family Card Search - Church Admin Office" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        {/*View*/}
        <div >
          <Modal onHide={toggle} show={!collapsed} size="lg" >
          <div  ref={componentModelRef}>
             <Modal.Header  closeButton >
               Registration Form - {familyCardFormType}
                    </Modal.Header>
                    <Modal.Body >    
                        <FamilyCardForm handleSearch={handleSearch} toggle={toggle} /> 
                    </Modal.Body>
                    <Modal.Footer>
            {' '}
            <ReactToPrint
        content={reactToPrintContentModal}
        documentTitle="AwesomeFileName"
        
        trigger={reactToPrintTriggerModal}
      />
            <Button onClick={toggle}>
                Close
            </Button>
            </Modal.Footer>
          </div>
                   
        </Modal>
        </div>

        {/*Payment*/}
        <Modal onHide={newToggle} show={!newCollapsed} size="lg">
            <Modal.Header toggle={newToggle} closeButton>
            Payment - Add
            </Modal.Header>
            <Modal.Body>    
            <div>
            <PaymentForm formType={"New"} newToggle={newToggle}/> 
              </div>
            </Modal.Body>
            <Modal.Footer>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </Modal.Footer>
          </Modal>


      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i>            </Breadcrumb.Item>
            <Breadcrumb.Item>Family Card Details</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Search Family Card</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="student"
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadCardHeaders}
              filename="download.csv"
              data={displayResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="FamilyID" value={searchTopic} onChange={(event)=>{handleSearchTopicSelection(event)}}>
                <option value="FamilyID">Family ID</option>
                <option value="FamilyHead">Family Head</option>
                <option value="FamilyZone">Anbiyam</option>
                  <option value="FamilyStatus">Status</option>
                  <option value="FamilyDueSub">Subscription Due</option>

                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
              <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
              {searchTopic==="FamilyStatus"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {cardStatusData.map(item => {
                    return (
                      <option key={item.name} value={item.name}>{item.name}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyAnbiyam"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {churchMaster.chruchAnbiyam.map(item => {
                    return (
                      <option key={item.id} value={item.anbiyamName}>{item.anbiyamName}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyZone"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {churchMaster.chruchAnbiyam.map(item => {
                    return (
                      <option key={item.id} value={item.anbiyamName}>{item.anbiyamName}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyDueSub"? 
            <Form.Control type="text"  autoFocus placeholder="Search" disabled value={searchValue} onChange={(event)=>{setSearchValue(30)}}/>:
            <Form.Control type="text"  autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

     <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Family Card ID</th>
                <th>Family Head</th>
                <th>Members</th>
                <th>Anbiyam</th>
                <th>Registered Date</th>
                <th>Subscription Paid</th>
                <th>Status</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.familyID}</td>
            <td><img id="profile" src={`${config.s3.imageURL}${loggedUser.familyName}/${item.familyID}/${item.memberID}.jpeg`} 
            onError={handleImageError}
            alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%", paddingRight:5}} />{item.familyHead}</td>
            <td>{item.familyMembers}</td>
            <td>{item.familyAnbiyam}</td>
            <td>{item.registrationDate}</td>
            <td>{item.lastSubscriptionDate}</td>
            <td>{item.cardStatus==="Cancelled"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Cancelled</p>:
            item.cardStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Active</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Expired</p>}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
              <i class="fa fa-pencil" aria-hidden="true"></i> Edit Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handlePaymentForm(event, item, "Add")}}>
              <i class="fa fa-pencil" aria-hidden="true"></i> Add Payment
              </Dropdown.Item>
            </Dropdown.Menu>
           </Dropdown>
           </td>
           </tr>)}
           </tbody>
      </Table>
    </>
  )
}
