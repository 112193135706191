import React, {useContext} from 'react';
import { Button, ButtonGroup, Dropdown, Table } from 'react-bootstrap';
//import ReactToPrint from "react-to-print";
import { ProductContext } from '../../ProductProvider';
//import FamilyMembersForm from './FamilyMembersForm';
import { useSnackbar } from 'notistack';

const config = require('../../config.json');

export default function ListFamilyMembers(props) {

    const {toggleMemberForm, displayMembers, collapsed, familyCardFormType } = props;
    const { loggedUser, setFamilyMemberFormType, setFamilyMembers, handleImageError} = useContext(ProductContext);
    const componentModelRef = React.useRef(null);
    const componentRef = React.useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const reactToPrintContent = React.useCallback(() => {
      return componentRef.current;
    }, [componentRef.current]);
  
    const reactToPrintTrigger = React.useCallback(() => {
      return <Button variant="outline-primary"  size="sm">Print</Button>;
    }, []);
  
    const reactToPrintContentModal = React.useCallback(() => {
      return componentModelRef.current;
    }, [componentModelRef.current]);
  
    const reactToPrintTriggerModal = React.useCallback(() => {
      return <Button   >Print</Button>;
    }, []);  

    const handleNewProcessing=(event)=>{
        event.preventDefault();
        try{
            //console.log("Test");
            toggleMemberForm();
        }catch(error){

        }
    }
    
    const handleMembersView=(event, item, action)=>{
      event.preventDefault();
      try{
          setFamilyMemberFormType(action);
          setFamilyMembers(item);
          toggleMemberForm();
      }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      }
    }
  

  return (
    <>
    {familyCardFormType==="View"?null:<Button variant="primary" type="submit" onClick={(event)=>{handleNewProcessing(event)}}>New</Button>}
    <div>Family Members</div>
    <Table hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>RelationShip</th>
                <th>Phone No</th>
                <th>emailID</th>
                <th>Status</th>
                {familyCardFormType==="View"?null:<th>Action</th>}
              </tr>
            </thead>
            <tbody>
            {displayMembers.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td><div className='d-flex justify-content-start'><img src={`${config.s3.imageURL}${loggedUser.familyName}${"/"}${item.memberID.substring(0,10)}${"/"}${item.memberID}`+".jpeg"} className="user-avatar md-avatar rounded-circle "  onError={handleImageError} style={{width:"3rem", height:"3rem", borderRadius:"50%", paddingRight:5}}/><span style={{paddingLeft:'10px', paddingTop:'15px'}}>{item.title}. {item.memberName}</span></div></td>
            <td>{item.relationship}</td>
            <td>{item.areaCode}-{item.phoneNo}</td>
            <td>{item.emailID}</td>
            <td>{item.memberStatus}</td>
            {familyCardFormType==="View"?null:<td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleMembersView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i>  View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleMembersView(event, item, "Edit")}}>
              <i class="fa fa-pencil" aria-hidden="true"></i> Edit Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>}
            </tr>)}
            </tbody>
      </Table>
    </>
  )
}
