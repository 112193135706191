import React from 'react';

export default function Footer() {
  return (
    <div>
        <div class="container-fluid">
            <div class="footer">
            <p>Copyright © 2025 Designed by idntica.com. All rights reserved.</p>
            </div>
        </div>
    </div>
  )
}
