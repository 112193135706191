import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from 'react-bootstrap';
import {Modal  } from "react-bootstrap";
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { fetchAuthSession } from 'aws-amplify/auth';
import { CSVLink } from "react-csv";
import { cardStatusData, downloadCardHeaders } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import GraveYardPic from "../graveyard/GraveYardPic";
import GraveYardForm from "../graveyard/GraveYardForm";
import BurialList from "./BurialList";
import { useSnackbar } from 'notistack';

//import GraveYardForm from "../graveyard/GraveYardForm";
//import GraveYardPic from "../graveyard/GraveYardPic";

const config = require('../../config.json');


export default function GraveYardSearch() {
    const { loggedUser, validateLogin, familyCardFormType, setFamilyCardFormType, setCardDetails, displayMembers, setDisplayMembers, churchMaster, setDisplaySacrements, handleImageError} = useContext(ProductContext);
    const csvLinkEl = React.createRef();
    //const [applicationDetails, setApplicationDetails]=useState([]);
    const [searchTopic, setSearchTopic] = useState('FamilyID');
    const [searchValue, setSearchValue] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [displayResults, setDisplayResults] = useState([]);
    const componentRef = React.useRef(null);
    const [collapsed, setCollapsed] = useState(true);
    const [picCollapsed, setPicCollapsed] = useState(true);
    const [selectedItem, setSelectedItem] = useState();
    const [listCollapsed, setListCollapsed]=useState(true);
    const [listResult, setListResult]=useState([]);
    const [formType, setFormType]= useState("None");
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
      if(loggedUser.isAuthenticated){
        //processBatchYear();
      }else{
      validateLogin();
      }
     // console.log(batchYear);
    },[loggedUser])


    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);
    
      const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="outline-primary"  size="sm">Print</Button>;
      }, []);

      const handleDownload =async(event)=>{
        event.preventDefault();
        csvLinkEl.current.link.click();
      }

      const handleSearch=async()=>{
        //event.preventDefault();
        try{
          if(searchValue===""){
            enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant : 'info'});
          }else{
            setLoaded(false);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            var tsearchValue=searchValue
            if(searchTopic==="FamilyID"||searchTopic==="GraveyardNo"){
              tsearchValue=searchValue.toUpperCase();
              setSearchValue(tsearchValue);
            }
    
            const clientID=loggedUser.familyName;
            //console.log("search topic : ", searchTopic);
            var searchQuery=`${config.api.invokeUrl}/dashboard/family_card`;
            if (searchTopic==="GraveyardNo"){
              searchQuery=`${config.api.invokeUrl}/dashboard/family-graveyard`;
            }else{
              searchQuery=`${config.api.invokeUrl}/dashboard/family_card`;
            }
            await axios.get(`${searchQuery}?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            //console.log("response : ", response);
            if(response.data.length>0)
            {
              //console.log("response1 : ", response);
                //setDisplayResults(response.data[0]);
                if(searchTopic==="FamilyID"){
                  //setDisplayMembers(response.data[1]);
                  await sortValues(response.data[0]);
                  setCardDetails(response.data[0][0]);
                  setFormType("New");  
                  setDisplaySacrements(response.data[1]);
                  enqueueSnackbar(response.data[0].length + " - Family card records found!",{variant : 'success'});
                }else{
                 await setListResult(response.data);
                 enqueueSnackbar(response.data.length + " - records found!",{variant : 'success'});
                }
            }else{
              enqueueSnackbar("0 - records found!",{variant : 'success'});
            }
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
      })
    }
        }catch(error){
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        }
      }

      const handleSearchTopicSelection=(event)=>{
        event.preventDefault();
        setSearchTopic(event.target.value);
        if(event.target.value==="FamilyStatus"){
          setSearchValue("Active");
        }else if(event.target.value==="FamilyZone"){
          //console.log("anbiyam : ", churchMaster.chruchAnbiyam[0].anbiyamName);
          setSearchValue(churchMaster.chruchAnbiyam[0].anbiyamName);
        }else{
          setSearchValue();
        }
      
      }

      const sortValues=(tempData)=>{
        //console.log("TEmp Data : ", tempData);
        //var sortedOptionArray = tempData.sort((a, b) => {return a.familyAnbiyam - b.familyAnbiyam;});
        const result = tempData.sort((a, b) =>a.familyAnbiyam.localeCompare(b.familyAnbiyam));
        //const result = tempData.sort((a, b) =>a.familyID.localeCompare(b.familyID));
        //console.log("Sorted Data : ", result);
        setDisplayResults(result);
        /*setCourseOptionAvailable(sortedOptionArray);
        setOptedCourse({...optedCourse,
          courseName:sortedcourseArray[0].courseName,
          courseOption:sortedOptionArray[0].courseOption
        });*/
    }

    const toggle = () => {
        //console.log("clicked");
          setCollapsed(!collapsed);
   }

   const listToggle = () => {
    //console.log("clicked");
      setListCollapsed(!listCollapsed);
}


  const handlePictureDisplay=(event, item)=>{
    event.preventDefault();
    if(item.graveYardNo==="NA"||item.graveYardNo==="-"||item.graveYardNo===" "){
      enqueueSnackbar("Please check the Grave Yard No!",{variant : 'info'});
    }else{
      setSelectedItem(item);
      togglePicture();  
    }
  }

  const togglePicture = () => {
      //console.log("clicked");
        setPicCollapsed(!picCollapsed);
  }

const handleApplicationView=(event, item, type)=>{
  event.preventDefault();
  setSelectedItem(item);
  toggle();
}

const handleListView=async(event, item, type)=>{
  event.preventDefault();
  try{
    setLoaded(false);
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const clientID=loggedUser.familyName;
    //console.log("search topic : ", searchTopic);
    var searchQuery=`${config.api.invokeUrl}/dashboard/family-graveyard`;
    const tsearchValue=item.graveYardNo;
    const tsearchTopic="GraveYardNo";
    await axios.get(`${searchQuery}?fetchKey=${tsearchValue}&fetchOption=${tsearchTopic}&clientID=${clientID}`, {
    headers: {
      Authorization: idToken,
      'x-api-key':config.api.key
    }
  }).then(async(response) => {
    //console.log("response : ", response);
       // await sortValues(response.data[0]);
       setListResult(response.data);
       listToggle();
       enqueueSnackbar(response.data.length + " - records found!",{variant : 'success'});
    setLoaded(true);
}).catch((error)=>{
  enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
setLoaded(true);
})

  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  setLoaded(true);
  
  }
 // setSelectedItem(item);
}

  return (
    <div>
         <Preloader show={loaded ? false : true} />
         {/*MemberForm*/}
         <div >
          <Modal onHide={toggle} show={!collapsed} size="lg" >
          <div >
             <Modal.Header  closeButton>
               Graveyard Form - {familyCardFormType}
                  </Modal.Header>
                    <Modal.Body >    
                        <GraveYardForm formType="New"/> 
                    </Modal.Body>
                    <Modal.Footer>
            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </Modal.Footer>
          </div>      
        </Modal>
        </div>

        {/*Picture View*/}
        <div >
          <Modal onHide={togglePicture} show={!picCollapsed} size="lg" >
          <div >
             <Modal.Header  closeButton >
               Graveyard Picture - {familyCardFormType}
                    </Modal.Header>
                    <Modal.Body >    
                        <GraveYardPic formType="New" selectedItem={selectedItem}/>
                    </Modal.Body>
                    <Modal.Footer>
            {' '}
            <Button onClick={togglePicture}>
                Close
            </Button>
            </Modal.Footer>
          </div>      
        </Modal>
        </div>

        {/*List View*/}
        <div >
          <Modal onHide={listToggle} show={!listCollapsed} size="lg" >
          <div >
             <Modal.Header  closeButton >
               Graveyard List - {familyCardFormType}
                    </Modal.Header>
                    <Modal.Body >    
                      <BurialList listResult={listResult}/>
                    </Modal.Body>
                    <Modal.Footer>
            {' '}
            <Button onClick={listToggle}>
                Close
            </Button>
            </Modal.Footer>
          </div>      
        </Modal>
        </div>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i> </Breadcrumb.Item>
            <Breadcrumb.Item>Family Graveyard</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Search Family Graveyard</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="student"
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadCardHeaders}
              filename="download.csv"
              data={displayResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="FamilyID" value={searchTopic} onChange={(event)=>{handleSearchTopicSelection(event)}}>
                <option value="FamilyID">Family ID</option>
                <option value="GraveyardNo">GraveyardNo</option>

                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
              <i className="fa fa-search" aria-hidden="true"></i> 
              </InputGroup.Text>
              {searchTopic==="FamilyStatus"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {cardStatusData.map(item => {
                    return (
                      <option key={item.name} value={item.name}>{item.name}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyAnbiyam"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {churchMaster.chruchAnbiyam.map(item => {
                    return (
                      <option key={item.id} value={item.anbiyamName}>{item.anbiyamName}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyZone"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {churchMaster.chruchAnbiyam.map(item => {
                    return (
                      <option key={item.id} value={item.anbiyamName}>{item.anbiyamName}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyDueSub"? 
            <Form.Control type="text"  autoFocus placeholder="Search" disabled value={searchValue} onChange={(event)=>{setSearchValue(30)}}/>:
            <Form.Control type="text"  autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

     {searchTopic==="FamilyID" && displayResults.length>0? <>
     <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Family Card ID</th>
                <th>Family Head</th>
                <th>Graveyard No</th>
                <th>Anbiyam</th>
                <th>Last Updated</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.familyID}</td>
            <td><img id="profile" src={`${config.s3.imageURL}${loggedUser.familyName}/${item.familyID}/${item.memberID}.jpeg`} 
            onError={handleImageError}
            alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%", paddingRight:5}} />{item.familyHead}</td>
            <td>{item.graveYardNo}</td>
            <td>{item.familyAnbiyam}</td>
            <td>{item.updatedOn}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i> 
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleListView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i>  View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{toggle(event, item)}}>
              <i className="fa fa-pencil" aria-hidden="true"></i>  New Entry
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handlePictureDisplay(event, item)}}>
              <i className="fa fa-eye" aria-hidden="true"></i>  View Photos
              </Dropdown.Item>
            </Dropdown.Menu>
           </Dropdown>
           </td>
           </tr>)}
           </tbody>
      </Table></>:null}

      {searchTopic==="GraveyardNo" && listResult.length>0 ?
      <BurialList listResult={listResult} />:null}
    
    </div>
  )
}
