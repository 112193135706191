import React, { useEffect, useState, useContext, useRef } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import { Button, ButtonGroup, Breadcrumb } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader/Preloader";
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
//import FileAttachments from "../../components/attachments/FileAttachments";
//import ProfileImage from "../../components/manageimage/ProfileImage";
import axios from "axios";
import ListFamilyMembers from "./ListFamilyMembers";
import ListFamilySacrements from "./ListFamilySacrements";
const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


export  default function FamilySacrementsForm(props) {
  const { toggleSacrementForm} = props;
  const { loggedUser, generateDate, validateLogin, displaySacrements, setDisplaySacrements, setFamilyCardFormType, cardDetails, setCardDetails, familySacrements, setFamilySacrements, sacrementsFormType } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);
  const [viewOnly, setViewOnly] = useState(true);
  const [subViewOnly, setSubViewOnly] = useState(true);
  const componentRef = React.useRef(null);
  const inputTitle = useRef(null);
  const inputAddressCity=useRef(null);
  const inputAddressPincode=useRef(null);
  const inputAddressState=useRef(null);
  const inputAreaCode=useRef(null);
  const inputCategoryID=useRef(null);
  const inputContactNo=useRef(null);
  const inputCopies=useRef(null);
  const inputCountry=useRef(null);
  const inputHouseName=useRef(null);
  const inputAddressStreet=useRef(null);
  const inputEmailID =useRef(null);
  const inputRegDate=useRef(null);
  const inputEndDate=useRef(null);
  const inputStartDate=useRef(null);
  const inputSubStatus=useRef(null);
  const inputSubscriberName=useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      if(sacrementsFormType==="New"){
        setViewOnly(false);
        resetValues();
        //generateID();
    }else if(sacrementsFormType==="Edit"){
      setViewOnly(false);
    }else if(sacrementsFormType==="View"){
      setViewOnly(true);
    }
  }else{
    resetValues();
    validateLogin();
  }
    return () => {
      if(sacrementsFormType==="New"){
        resetValues();
      }
        setFamilyCardFormType("New");
      }
  },[]) 
  
  const handleEventKey=(event, item)=>{
    if(event.key === 'Enter'){
        if(item==="1"){
            inputSubscriberName.current.focus();
        }else if(item==="2"){
          inputRegDate.current.focus();
        }else if(item==="3"){
          inputSubStatus.current.focus();
        }else if(item==="4"){
          inputStartDate.current.focus();
        }else if(item==="5"){
          inputEndDate.current.focus();
        }else if(item==="6"){
          inputEmailID.current.focus();
        }else if(item==="7"){
          //console.log("event : ", event);
            inputAreaCode.current.focus();
        }else if(item==="8"){
          inputContactNo.current.focus();
        }else if(item==="9"){
          inputCategoryID.current.focus();
        }else if(item==="10"){
          inputCopies.current.focus();
        }else if(item==="11"){
          inputHouseName.current.focus();
        }else if(item==="12"){
          inputAddressStreet.current.focus();
        }else if(item==="13"){
          inputAddressCity.current.focus();
        }else if(item==="14"){
          inputAddressState.current.focus();
        }else if(item==="15"){
          inputAddressPincode.current.focus();
        }else if(item==="16"){
          inputCountry.current.focus();
        }
    }
}

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleSubcriberEdit=async(event)=>{
    event.preventDefault();
    try{
    const validationResult=validateFields();
    if(validationResult==="passed"){
      setLoaded(false);
      const tupdatedOn=await generateDate();
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const clientID=loggedUser.familyName;

      const { memberID, title, memberName, isBaptised, baptismDate,isEucharist, eucharistDate, isConfirmation, confirmationDate,isMarried,marriageDate,
        confirmationPlace,confirmationChurch,marriagePlace,marriageChurch,
        baptismChruch,baptismPlace,eucharistChruch, eucharistPlace}=familySacrements;

      const params={clientID:clientID, memberID:memberID, title:title, memberName:memberName, isBaptised:isBaptised, baptismDate:baptismDate,isEucharist:isEucharist, eucharistDate:eucharistDate, isConfirmation:isConfirmation, confirmationDate:confirmationDate,isMarried:isMarried,marriageDate:marriageDate,
        confirmationPlace:confirmationPlace,confirmationChurch:confirmationChurch,marriagePlace:marriagePlace,marriageChurch:marriageChurch,
        baptismChruch:baptismChruch,baptismPlace:baptismPlace,eucharistChruch:eucharistChruch, eucharistPlace:eucharistPlace, updatedBy:loggedUser.username, updatedOn:tupdatedOn};

      await axios.patch(`${config.api.invokeUrl}/dashboard/family_sacrements`, params,{
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{

          const tsacrements=displaySacrements;
          const sacrementsToUpdate = tsacrements.find(obj => obj.memberID === memberID);
          const updatedSacrements = tsacrements.filter(obj => obj.memberID !== memberID);
          sacrementsToUpdate.isBaptised = isBaptised;
          sacrementsToUpdate.baptismDate = baptismDate;
          sacrementsToUpdate.isEucharist = isEucharist;
          sacrementsToUpdate.eucharistDate = eucharistDate;
          sacrementsToUpdate.isConfirmation = isConfirmation;
          sacrementsToUpdate.confirmationDate = confirmationDate;
          sacrementsToUpdate.isMarried = isMarried;
          sacrementsToUpdate.marriageDate = marriageDate;
          sacrementsToUpdate.confirmationPlace = confirmationPlace;
          sacrementsToUpdate.confirmationChurch = confirmationChurch;
          sacrementsToUpdate.marriagePlace = marriagePlace;
          sacrementsToUpdate.marriageChurch = marriageChurch;
          sacrementsToUpdate.baptismChruch = baptismChruch;
          sacrementsToUpdate.baptismPlace = baptismPlace;
          sacrementsToUpdate.eucharistChruch = eucharistChruch;
          sacrementsToUpdate.eucharistPlace = eucharistPlace;
          sacrementsToUpdate.marriageChurch = marriageChurch;
          updatedSacrements.push(sacrementsToUpdate);
          setDisplaySacrements(updatedSacrements); 
          enqueueSnackbar(memberID + " - Successfully Updated.",{variant : 'success'});
        window.scrollTo(0,0);
        toggleSacrementForm();
        setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const generateSubscriberID=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    return accountNo;
}

  const handleSubcriberNew=async(event)=>{
    event.preventDefault();
    try{
    //  console.log("value : ", emailCodeEntered, emailCodeReceived);
    //const validationResult=validateFields();
    const validationResult="passed";
    if(validationResult==="passed"){

      setLoaded(false);
      const { memberID, title, memberName, isBaptised, baptismDate,isEucharist, eucharistDate, isConfirmation, confirmationDate,isMarried,marriageDate,
        isDead, deathDate,confirmationPlace,confirmationChurch,marriagePlace,marriageChurch,deathPlace,deathChurch,
        burriedPlace,baptismChruch,baptismPlace,eucharistChruch, eucharistPlace}=familySacrements;

      //const clientID = "VJDEPTH";
      const todayDate=await generateDate();
      //const applicationStatus="Pending Approval";
      //const tcourseStatus="Pending Approval";
      //const tcoursePayment="Pending";   
      //const tapplicantName=firstName+ " "+middleName+ " "+lastName;    
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      //const subscriberid=subscriberID;

      const params={ memberID:memberID, title:title, memberName:memberName, isBaptised:isBaptised, baptismDate:baptismDate,isEucharist:isEucharist, eucharistDate:eucharistDate, isConfirmation:isConfirmation, confirmationDate:confirmationDate,isMarried:isMarried,marriageDate:marriageDate,
      isDead:isDead, deathDate:deathDate,confirmationPlace:confirmationPlace,confirmationChurch:confirmationChurch,marriagePlace:marriagePlace,marriageChurch:marriageChurch,deathPlace:deathPlace,deathChurch:deathChurch,
      burriedPlace:burriedPlace,baptismChruch:baptismChruch,baptismPlace:baptismPlace,eucharistChruch:eucharistChruch, eucharistPlace:eucharistPlace};

      console.log("Params : ", params);
      /*await axios.post(`${config.api.invokeUrl}/subscribers/${subscriberid}`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          console.log("response : ", response); 
          if(response.status===201){
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Subscriber ID - " + subscriberid + " - Successfully Registered."
        }); 
        window.scrollTo(0,0);
        resetValues();
      }else if(response.status===251){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Duplicate Subscriber ID - "+ subscriberid + " - Found. Correct or Refresh the Subscriber ID and Try Again."
      }); 
      window.scrollTo(0,0);      
      }
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })*/
        setLoaded(true);

    }
      
      }catch(error){
  //      console.log("error : ", error);
  enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const resetValues=()=>{
    const todayDate= generateDate();
    const subscriberid= generateSubscriberID();
    setCardDetails({...cardDetails,subscriberID:subscriberid, title:'Mr',subscriberName:'', areaCode:'+91',contactNo:'', emailID:'', houseName:'', addressStreet:'', addressCity:'', addressState:'',
    addressPincode:'', country:'', copies:'', categoryID:'A',subStatus:'Active', regDate:todayDate, startDate:todayDate, endDate:todayDate});
  }

  const validateFields=()=>{

    const { isBaptised, baptismDate,isEucharist, eucharistDate, isConfirmation, confirmationDate,isMarried,marriageDate,
      isDead, deathDate,confirmationPlace,confirmationChurch,marriagePlace,marriageChurch,deathPlace,deathChurch,
      burriedPlace,baptismChruch,baptismPlace,eucharistChruch, eucharistPlace}=familySacrements;

        var returnValue="passed";
        if(isBaptised==="Yes"){
          if(baptismDate===""||baptismChruch===""||baptismPlace===""){
            enqueueSnackbar("All Baptism details are must to be filled!",{variant : 'info'});
          returnValue="failed";  
          }
        }else if(isEucharist==="Yes"){
          if(eucharistDate===""||eucharistChruch===""||eucharistPlace===""){
            enqueueSnackbar("All Eucharist details are must to be filled!",{variant : 'info'});
      returnValue="failed";
    }
      }else if(isConfirmation==="Yes"){
        if(confirmationDate===""||confirmationChurch===""||confirmationPlace===""){
          enqueueSnackbar("All Confirmation details are must to be filled!",{variant : 'info'});
      returnValue="failed";
    }
    }else  if(isMarried==="Yes"){
      if(marriageDate===""||marriageChurch===""||marriagePlace===""){
        enqueueSnackbar("All Marriage details are must to be filled!",{variant : 'info'});
    returnValue="failed";
  }
  }else  if(isDead==="Yes"){
    if(deathDate===""||deathChurch===""||burriedPlace===""||deathPlace===""){
      enqueueSnackbar("All Death details are must to be filled!",{variant : 'info'});
  returnValue="failed";
}
  }else {
    returnValue="passed";
  }
  window.scrollTo(0,0);
  return returnValue;
  }

  const handleCheckBoxClick=async(selectItem)=>{
    //event.preventDefault();
    //console.log("select item : ", selectItem);
    if(selectItem==="Baptism"){
      const res=familySacrements.isBaptised==="Yes"?"No":"Yes";
      //console.log("Res: ", res);
      await setFamilySacrements({...familySacrements,isBaptised:res});  
    }else if(selectItem==="Eucharist"){
      const res=familySacrements.isEucharist==="Yes"?"No":"Yes";
      //console.log("Res: ", res);
      await setFamilySacrements({...familySacrements,isEucharist:res});  
    }else if(selectItem==="Confirmation"){
      const res=familySacrements.isConfirmation==="Yes"?"No":"Yes";
      //console.log("Res: ", res);
      await setFamilySacrements({...familySacrements,isConfirmation:res});  
    }else if(selectItem==="Marriage"){
      const res=familySacrements.isMarried==="Yes"?"No":"Yes";
      //console.log("Res: ", res);
      await setFamilySacrements({...familySacrements,isMarried:res});  
    }else if(selectItem==="Dead"){
      const res=familySacrements.isDead==="Yes"?"No":"Yes";
      //console.log("Res: ", res);
      await setFamilySacrements({...familySacrements,isDead:res});  
    }
    //console.log("Sacrements : ", familySacrements);
  }


  return (
    <div>
        <Preloader show={loaded ? false : true} />

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item>Registration</Breadcrumb.Item>
            <Breadcrumb.Item active>Holy Sacrements</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>
        </div>
     
      <div ref={componentRef}>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Row>
        <Col md={6} className="mb-3">
              <Form.Group id="subscriberid">
                <Form.Label>Name</Form.Label>
                <Form.Control required type="text" value={familySacrements.memberName}
                  disabled
                  //onChange={(event)=>{setFamilySacrements({...familySacrements, memberName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>

            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>ID</Form.Label>
                <Form.Control type="text"  value={familySacrements.memberID}
                  disabled ref={inputSubscriberName} onKeyDown={event=>{handleEventKey(event,"2")}} 
                  //onChange={(event)=>{setFamilyMembers({...familyMembers, relationship:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
          </Row> 
          <Card>
          <Row>
          <Col sm={3} className="mb-3">
          <ButtonGroup>
          <Form.Group
            inline
            check
          >
            <Form.Check type="checkbox" defaultChecked={false} disabled={viewOnly} checked={familySacrements.isBaptised==="Yes"?true:false} onChange={event=>handleCheckBoxClick("Baptism")}/>
            <Form.Label check>
              Baptised
            </Form.Label>
          </Form.Group>
          </ButtonGroup>
            </Col>
           {familySacrements.isBaptised==="Yes"? <Row>
            <Col sm={3} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" required value={familySacrements.baptismDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, baptismDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={5} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Church</Form.Label>
                <Form.Control required type="text"  value={familySacrements.baptismChruch}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, baptismChruch:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Place</Form.Label>
                <Form.Control required type="text"  value={familySacrements.baptismPlace}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, baptismPlace:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            </Row>:null}
          </Row>
          </Card>
          <Card>
          <Row>
          <Col sm={3} className="mb-3">
          <ButtonGroup>
          <Form.Group
            inline
            check
          >
            <Form.Check type="checkbox" disabled={viewOnly} checked={familySacrements.isEucharist==="Yes"?true:false} onChange={event=>handleCheckBoxClick("Eucharist")}/>
            <Form.Label check>
              Eucharist
            </Form.Label>
          </Form.Group>
          </ButtonGroup>
            </Col>
            {familySacrements.isEucharist==="Yes"? <Row>
            <Col sm={3} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" required value={familySacrements.eucharistDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, eucharistDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={5} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Church</Form.Label>
                <Form.Control required type="text"  value={familySacrements.eucharistChruch}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, eucharistChruch:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Place</Form.Label>
                <Form.Control required type="text"  value={familySacrements.eucharistPlace}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, eucharistPlace:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            </Row>:null}
          </Row>
          </Card>
          <Card>
          <Row>
          <Col sm={3} className="mb-3">
          <ButtonGroup>
          <Form.Group
            inline
            check
          >
            <Form.Check type="checkbox" disabled={viewOnly} checked={familySacrements.isConfirmation==="Yes"?true:false} onChange={event=>handleCheckBoxClick("Confirmation")}/>
            <Form.Label check>
              Confirmation
            </Form.Label>
          </Form.Group>
          </ButtonGroup>
            </Col>
            {familySacrements.isConfirmation==="Yes"? <Row>
            <Col sm={3} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" required value={familySacrements.confirmationDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, confirmationDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={5} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Church</Form.Label>
                <Form.Control required type="text"  value={familySacrements.confirmationChurch}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, confirmationChurch:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Place</Form.Label>
                <Form.Control required type="text"  value={familySacrements.confirmationPlace}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, confirmationPlace:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>:null}
          </Row>
          </Card>
          <Card>
          <Row>
          <Col sm={3} className="mb-3">
          <ButtonGroup>
          <Form.Group
            inline
            check
          >
            <Form.Check type="checkbox" disabled={viewOnly}  checked={familySacrements.isMarried==="Yes"?true:false} onChange={event=>handleCheckBoxClick("Marriage")}/>
            <Form.Label check>
              Marriage
            </Form.Label>
          </Form.Group>
          </ButtonGroup>
            </Col>
            {familySacrements.isMarried==="Yes"? <Row>
            <Col sm={3} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" required value={familySacrements.marriageDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, marriageDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={5} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Church</Form.Label>
                <Form.Control required type="text"  value={familySacrements.marriageChurch}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, marriageChurch:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Place</Form.Label>
                <Form.Control required type="text"  value={familySacrements.marriagePlace}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, marriagePlace:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            </Row>:null}
          </Row>
          </Card>
          {/*<Card>
          <Row>
          <Col sm={3} className="mb-3">
          <ButtonGroup>
          <FormGroup
            inline
            check
          >
            <Input type="checkbox" disabled={viewOnly}  checked={familySacrements.isDead==="Yes"?true:false} onChange={event=>handleCheckBoxClick("Dead")}/>
            <Label check>
              Dead
            </Label>
          </FormGroup>
          </ButtonGroup>
            </Col>
            {familySacrements.isDead==="Yes"? <Row>
            <Col sm={3} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" required value={familySacrements.deathDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, deathDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={5} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Church</Form.Label>
                <Form.Control required type="text"  value={familySacrements.deathChurch}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, deathChurch:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Place</Form.Label>
                <Form.Control required type="text"  value={familySacrements.deathPlace}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilySacrements({...familySacrements, deathPlace:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>:null}
          </Row>
          </Card>*/}
    {sacrementsFormType==="Edit"?<div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleSubcriberEdit(event)}}>Update</Button>
        </div>:null}
</Card.Body>
</Card>   
</div>
</div>
  )
}
