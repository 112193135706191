import React, {useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Button, FormGroup, Form, ButtonGroup, Modal, Card, Col } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import SubscriberForm from '../subscription/SubscriberForm';
import PaymentForm from '../payment/PaymentForm';
import {Helmet} from 'react-helmet-async';
//import ReactToPrint from "react-to-print";
//import { CSVLink } from "react-csv";
//import { downloadSubscriberHeaders } from '../../ProData';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function RenewalList(props) {
  const { loggedUser, validateLogin, subscriberFormType, setSubscriberFormType, setsubscriberDetails} = useContext(ProductContext);
  const {applicationDetails, displayResults, setDisplayResults}=props;
  const [loaded, setLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const [searchOption, setSearchOption] = useState('Active');
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [iSubscribers, setISubscribers]= useState([]);
  const [iSubDetails, setISubDetails] = useState({"I":[],"IM":[],"IE":[],"IX":[],"IC":[]});
  const [aSubDetails, setASubDetails] = useState({"A":[],"AM":[],"AE":[],"AX":[],"AC":[]});
  const [oSubDetails, setOSubDetails] = useState({"F":[],"FX":[],"FM":[],"CAT":[],"COMP":[]});
  const [aSubscribers, setASubscribers]= useState([]);
  //const [displayResults, setDisplayResults]= useState([]);
  const [displayCategory, setDisplayCategory]=useState('All');
  const [displaySubCategory, setDisplaySubCategory]=useState(null);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  const csvLinkEl = React.createRef();
  const [isEmailView, setIsEmailView]= useState(false);
  const [parkingList, setParkingList] = useState([]);

useEffect(()=>{
    if(loggedUser.isAuthenticated){
      processingData(applicationDetails);
      //setApplicationDetails(response.data);
      setDisplayResults(applicationDetails);
      setParkingList(applicationDetails);
      setDisplayCategory('All');
        //fetchSubscribersDetails('Active');
        //setSubscriberFormType('View');
    }else{
        validateLogin();
    }
},[loggedUser, applicationDetails])

const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button>Print</Button>;
  }, []);

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

const fetchSubscribersDetails=async(item)=>{
  try{
      setLoaded(false);
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const clientID="VJJournal";
      const applicationStatus=item;
      await axios.get(`${config.api.invokeUrl}/subscribers/status?clientID=${clientID}&subStatus=${applicationStatus}`,{
          headers: {
            Authorization: idToken,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        //console.log("Response : ", response);
          processingData(response.data);
          //setApplicationDetails(response.data);
          setDisplayResults(response.data);
          setDisplayCategory('All');
          enqueueSnackbar(response.data.length + " - " + searchOption + " Subscribers Found",{variant : 'success'});
          setLoaded(true);
      }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          setLoaded(true);
      })
  
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  }
}

const processingData=(resData)=>{
    const tempIData=resData.filter((item)=>item.categoryID.startsWith("I"))
    //console.log("India :", tempIData);
    setISubscribers(tempIData);
    const tempAData=resData.filter((item)=>item.categoryID.startsWith("A"))
    //console.log("Abroad :", tempAData);
    setASubscribers(tempAData);
    setLoaded(true);
}

const handleDisplayCategory=(event,item)=>{
    event.preventDefault();
    setDisplayCategory(item);
    setDisplaySubCategory(null);
    if(item==="Indian"){
        const tempIData=applicationDetails.filter((item)=>item.categoryID.startsWith("I"));
        const tempI=applicationDetails.filter((item)=>item.categoryID==="I-Indian");
        const tempIE=applicationDetails.filter((item)=>item.categoryID==="Indian E-Subscription");
        const tempIX=applicationDetails.filter((item)=>item.categoryID==="Indian Exchange");
        const tempIC=applicationDetails.filter((item)=>item.categoryID==="Indian Complimentary");
        setISubDetails({"I":tempI,"IE":tempIE,"IX":tempIX,"IC":tempIC});
        setDisplayResults(tempIData);
        setParkingList(tempIData);
    }else if(item==="Abroad"){
        const tempAData=applicationDetails.filter((item)=>item.categoryID.startsWith("A"));
        const tempA=applicationDetails.filter((item)=>item.categoryID==="A-Abroad");
        const tempAE=applicationDetails.filter((item)=>item.categoryID==="Abroad E-Subscription");
        const tempAX=applicationDetails.filter((item)=>item.categoryID==="Abroad Exchange");
        const tempAC=applicationDetails.filter((item)=>item.categoryID==="Abroad Complimentary");
        setASubDetails({"A":tempA,"AE":tempAE,"AX":tempAX,"AC":tempAC});
        setDisplayResults(tempAData);
        setParkingList(tempAData);
    }else if(item==="Others"){
        const tempOData=applicationDetails.filter((item)=>(item.categoryID.startsWith("F") || item.categoryID.startsWith("C")||item.categoryID.startsWith("O")));
        //console.log("Others :", tempOData);
        const tempF=applicationDetails.filter((item)=>item.categoryID==="F");
        const tempFX=applicationDetails.filter((item)=>item.categoryID==="FX");
        const tempFM=applicationDetails.filter((item)=>item.categoryID==="FM");
        const tempCAT=applicationDetails.filter((item)=>item.categoryID==="CAT");
        const tempCOMP=applicationDetails.filter((item)=>item.categoryID==="COMP");
        setOSubDetails({"F":tempF,"FX":tempFX,"FM":tempFM,"CAT":tempCAT,"COMP":tempCOMP});
        setDisplayResults(tempOData);
        setParkingList(tempOData);
    }else{
        setDisplayResults(applicationDetails);
        setParkingList(applicationDetails);
    }
    //setParkingList(displayResults);
}

const handleDisplaySubCategory=(event,item)=>{
    event.preventDefault();
    setDisplaySubCategory(item);
    if(item==="I"){
        setDisplayResults(iSubDetails.I);
        setParkingList(iSubDetails.I);
    }else if(item==="IM"){
        setDisplayResults(iSubDetails.IM);
        setParkingList(iSubDetails.IM);
    }else if(item==="IE"){
        setDisplayResults(iSubDetails.IE);
        setParkingList(iSubDetails.IE);
    }else if(item==="IX"){
        setDisplayResults(iSubDetails.IX);
        setParkingList(iSubDetails.IX);
    }else if(item==="IC"){
        setDisplayResults(iSubDetails.IC);
        setParkingList(iSubDetails.IC);
    }else if(item==="A"){
        setDisplayResults(aSubDetails.A);
        setParkingList(aSubDetails.A);
    }else if(item==="AM"){
        setDisplayResults(aSubDetails.AM);
        setParkingList(aSubDetails.AM);
    }else if(item==="AE"){
        setDisplayResults(aSubDetails.AE);
        setParkingList(aSubDetails.AE);
    }else if(item==="AX"){
        setDisplayResults(aSubDetails.A);
        setParkingList(aSubDetails.A);
    }else if(item==="AC"){
        setDisplayResults(aSubDetails.AC);
        setParkingList(aSubDetails.AC);
    }else if(item==="F"){
        setDisplayResults(oSubDetails.F);
        setParkingList(oSubDetails.F);
    }else if(item==="FX"){
        setDisplayResults(oSubDetails.FX);
        setParkingList(oSubDetails.FX);
    }else if(item==="FM"){
        setDisplayResults(oSubDetails.FM);
        setParkingList(oSubDetails.FM);
    }else if(item==="CAT"){
        setDisplayResults(oSubDetails.CAT);
        setParkingList(oSubDetails.CAT);
    }else if(item==="COMP"){
        setDisplayResults(oSubDetails.COMP);
        setParkingList(oSubDetails.COMP);
    }else{
        setDisplayResults(applicationDetails);
        setParkingList(applicationDetails);
    }
    //setParkingList(displayResults);
}

const toggle = () => {
  //   console.log("clicked");
       setCollapsed(!collapsed);
}

const newToggle = () => {
//   console.log("clicked");
      setNewCollapsed(!newCollapsed);
}

const handleApplicationView=(event, item, action)=>{
  event.preventDefault();
  //console.log("item :", item);
  try{
    setSubscriberFormType(action);
    setsubscriberDetails(item);
    toggle();
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }
}

const handlePaymentForm=(event, item, action)=>{
  event.preventDefault();
  try{
    setSubscriberFormType(action);
    setsubscriberDetails(item);
    newToggle();
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }

}

const handleEmailProcessing=async(event, item)=>{
  event.preventDefault();
  try{
    if(!item.emailID.match(validRegex)){
      enqueueSnackbar("Invalid Email Address!",{variant : 'info'});
    }else{
      setLoaded(false);
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const clientID="VJJournal";
      const params={
        clientID:clientID,
        emailID:item.emailID,
        subscriberName:item.subscriberName,
        subscriberID:item.subscriberID,
        endDate:item.endDate,
        copies:item.copies
      }
     // console.log("Params :", params);
      await axios.post(`${config.api.invokeUrl}/notifications/emailrenewal`, params,{
          headers: {
            Authorization: idToken,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
          console.log("Response : ", response);
          //setApplicationDetails(response.data);
          enqueueSnackbar("Email sent Successfully",{variant : 'success'});
          setLoaded(true);
      }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          setLoaded(true);
      })
    }
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  }
}

const handleSearchOption=(event, item)=>{
    event.preventDefault();
    setSearchOption(item);
    fetchSubscribersDetails(item);
}

const handleActiveSwitchChange=async()=>{
  if(isEmailView){
    const tempDisplay = parkingList;
    const tdisplay=tempDisplay.filter((item)=>!item.emailID.match(validRegex));
    setDisplayResults(tdisplay);
    setIsEmailView(!isEmailView);  
  }else{
    const tempDisplay = parkingList;
    const tdisplay=tempDisplay.filter((item)=>item.emailID.match(validRegex));
    setDisplayResults(tdisplay);
    setIsEmailView(!isEmailView);
  }
}

  return (
    <>
     <Helmet>
      <title>Renewal - Admin Office - St. Mary's Church, Madurai</title>
      <meta name="description" content="Renewal - Admin Office - St. Mary's Church, Madurai" />
      <meta name="keywords" content="Renewal - Admin Office - St. Mary's Church, Madurai" /> 
    </Helmet>
    <Preloader show={loaded ? false : true} />
    {/*View*/}
    <div >
          <Modal onHide={toggle} show={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div >
             <Modal.Header  show={toggle}>
               Registration Form - {subscriberFormType}
                    </Modal.Header>
                    <Modal.Body >    
                        <SubscriberForm handleSearch={fetchSubscribersDetails} toggle={toggle} /> 
                    </Modal.Body>
                    <Modal.Footer>
            {' '}
            {/*<ReactToPrint
        content={reactToPrintContentModal}
        documentTitle="AwesomeFileName"
        
        trigger={reactToPrintTriggerModal}
      />*/}
            <Button onClick={toggle}>
                Close
            </Button>
            </Modal.Footer>
          </div>
                   
        </Modal>
        </div>

        {/*Payment*/}
        <Modal onHide={newToggle} show={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <Modal.Header toggle={newToggle}>
            Payment - Add
            </Modal.Header>
            <Modal.Body>    
            <div>
            <PaymentForm formType={"New"} newToggle={newToggle}/> 
              </div>
            </Modal.Body>
            <Modal.Footer>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </Modal.Footer>
          </Modal>

          {/*<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item>Subscriber Details</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Active Subscriber's List</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="student"
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadSubscriberHeaders}
              filename="download.csv"
              data={displayResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>*/}

       {/*} <div className='d-flex justify-content-center p-2'>
            <Button  onClick={event=>handleSearchOption(event, 'Active')}>Active</Button>
            <Button onClick={event=>handleSearchOption(event,'Expired')}>Expired</Button>
            <Button onClick={event=>handleSearchOption(event, 'Suspended')}> Suspended</Button>
        </div>*/}
        {/*Switch Email View*/}
        
        <div className='d-flex justify-content-center p-2'>
        <Col>
            <Card className={displayCategory==='All'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplayCategory(event,"All")}>
            <Card.Subtitle className="mb-2 text-muted">Total {searchOption} </Card.Subtitle>
            <Card.Title>{applicationDetails.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Indian'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplayCategory(event,"Indian")}>
            <Card.Subtitle className="mb-2 text-muted">Indian</Card.Subtitle>
            <Card.Title>{iSubscribers.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Abroad'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplayCategory(event,"Abroad")}>
            <Card.Subtitle className="mb-2 text-muted">Abroad</Card.Subtitle>
            <Card.Title>{aSubscribers.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Others'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplayCategory(event,"Others")}>
            <Card.Subtitle className="mb-2 text-muted">Others</Card.Subtitle>
            <Card.Title>{applicationDetails.length-(aSubscribers.length+iSubscribers.length)}</Card.Title>
            </Card>
            </Col>
        </div>
        {displayCategory==="Indian"?<div className='d-flex justify-content-center'>
        <Col>
            <Card className={displayCategory==='Indian'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"I")}>
            <Card.Subtitle className="mb-2 text-muted">Indian (I) </Card.Subtitle>
            <Card.Title>{iSubDetails.I.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Indian'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"IE")}>
            <Card.Subtitle className="mb-2 text-muted">Indian E-Subscriptoin (IE)</Card.Subtitle>
            <Card.Title>{iSubDetails.IE.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Indian'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"IX")}>
            <Card.Subtitle className="mb-2 text-muted">Indian Exchange (IX)</Card.Subtitle>
            <Card.Title>{iSubDetails.IX.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Indian'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"IC")}>
            <Card.Subtitle className="mb-2 text-muted">Indian Complimentary (IC)</Card.Subtitle>
            <Card.Title>{iSubDetails.IC.length}</Card.Title>
            </Card>
            </Col>
        </div>:null}
        {displayCategory==="Abroad"?<div className='d-flex justify-content-center'>
        <Col>
            <Card className={displayCategory==='Abroad'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"A")}>
            <Card.Subtitle className="mb-2 text-muted">Abroad (A) </Card.Subtitle>
            <Card.Title>{aSubDetails.A.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Abroad'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"AE")}>
            <Card.Subtitle className="mb-2 text-muted">Abroad E-Subscriptoin (AE)</Card.Subtitle>
            <Card.Title>{aSubDetails.AE.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Abroad'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"AX")}>
            <Card.Subtitle className="mb-2 text-muted">Abroad Exchange (AX)</Card.Subtitle>
            <Card.Title>{aSubDetails.AX.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Abroad'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"AC")}>
            <Card.Subtitle className="mb-2 text-muted">Abroad Complimentary (AC)</Card.Subtitle>
            <Card.Title>{aSubDetails.AC.length}</Card.Title>
            </Card>
            </Col>
        </div>:null}
        {displayCategory==="Others"?<div className='d-flex justify-content-center'>
        <Col>
            <Card className={displayCategory==='Others'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"F")}>
            <Card.Subtitle className="mb-2 text-muted">F </Card.Subtitle>
            <Card.Title>{oSubDetails.F.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Others'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"FX")}>
            <Card.Subtitle className="mb-2 text-muted">FX</Card.Subtitle>
            <Card.Title>{oSubDetails.FX.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Others'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"FM")}>
            <Card.Subtitle className="mb-2 text-muted">FM</Card.Subtitle>
            <Card.Title>{oSubDetails.FM.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Others'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"CAT")}>
            <Card.Subtitle className="mb-2 text-muted">CAT</Card.Subtitle>
            <Card.Title>{oSubDetails.CAT.length}</Card.Title>
            </Card>
            </Col>
            <Col>
            <Card className={displayCategory==='Others'?'justify-content-center text-center p-2 border border-primary bg-info': 'justify-content-center text-center p-2 border border-primary'} onClick={event=>handleDisplaySubCategory(event,"COMP")}>
            <Card.Subtitle className="mb-2 text-muted">COMP</Card.Subtitle>
            <Card.Title>{oSubDetails.COMP.length}</Card.Title>
            </Card>
            </Col>
        </div>:null}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          <div className="d-block mb-4 mb-md-0">
          <h4>Displaying {displayResults.length} of {displayCategory} {displaySubCategory && ` - ${displaySubCategory}`} </h4>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0"> 
              <ButtonGroup>
              <FormGroup inline switch >
            <Form.Check
              type="switch"
              name="switchView"
              value="Active"
              label="Email Only"
              checked={isEmailView}
              onClick={() => {
                handleActiveSwitchChange(isEmailView)
              }}
            />
          </FormGroup>
            </ButtonGroup>
            </div>
        </div>

    {/*<Table responsive hover className="user-table align-items-center" ref={componentRef}>
         <thead>
            <tr>
            <th>#</th>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>End Date</th>
            <th>Category</th>
            <th>Action</th>
            </tr>
        </thead>
        <tbody>
        {displayResults.map((item, index)=>
        <tr key={index}>
        <th scope="row">{index+1}</th>
        <td>{item.subscriberID}</td>
        <td>{item.title}.{item.subscriberName}</td>
        <td>{item.emailID}</td>
        <td>{item.endDate}</td>
        <td>{item.categoryID}</td>
        <td>
       <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
          <span className="icon icon-sm">
          <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
          <i className="fa fa-eye" aria-hidden="true"></i>  View Details
          </Dropdown.Item>
          <Dropdown.Item className="text-success" onClick={(event)=>{handleEmailProcessing(event, item)}} >
          <i className="fa fa-envelope" aria-hidden="true"></i>  Send Email
          </Dropdown.Item>
          <Dropdown.Item className="text-danger" onClick={(event)=>{handlePaymentForm(event, item, "Add")}}>
          <i className="fa fa-plus" aria-hidden="true"></i>  Process Payment
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
        </td>
        </tr>)}
        </tbody>
        </Table>*/}

</>
  )
}
