import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import { Button, ButtonGroup, Breadcrumb } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader/Preloader";
import { fetchAuthSession } from 'aws-amplify/auth';
import JournalUploader from "./JournalUploader";
import PosterUploader from "./PosterUploader";
import axios from "axios";
import {Helmet} from 'react-helmet-async';
import { useSnackbar } from 'notistack';

const config = require('../../config.json');

export  default function AddPDF(props) {
  const {toggle, form, fetchJournalDetails} = props;
  const { loggedUser, generateDate, validateLogin, pdfFormType, setPDFFormType, pdfDetails, setPDFDetails } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);
  const [viewOnly, setViewOnly] = useState(true);
  const [journalFile, setJournalFile] = useState();
  const [posterFile, setPosterFile]= useState(null);
  const componentRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    //console.log("Props :", props);
    if(loggedUser.isAuthenticated){
      if(form==="New"){
        setViewOnly(false);
        setPDFFormType("New");    
        resetValues();
      }else if(pdfFormType==="New"){
        setViewOnly(false);
        resetValues();
        //generateID();
    }else if(pdfFormType==="Edit"){
      setViewOnly(false);
    }else if(pdfFormType==="View"){
      setViewOnly(true);
    }
  }else{
    resetValues();
    validateLogin();
  }
   /* return () => {
      if(pdfFormType==="Edit"||pdfFormType==="View"){
        setViewOnly(false);
        setPDFFormType("New");
        resetValues();
      
      }
    }*/
  },[])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleJournalEdit=async(event)=>{
    event.preventDefault();
    try{
    const validationResult=validateFields("Edit");
    if(validationResult==="passed"){
      setLoaded(false);
      const {journalName,journalID,  title,authorName, publishDate,journalStatus, description, remarks, endDate, journalCategory, 
        journalPrice, intlPrice, pages, publisherName}=pdfDetails;
      const tupdatedOn=await generateDate();
      const journalid=journalID;
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      var tremarks="";
      var tendDate="";
      if(journalStatus==="Active"){
        tremarks="NA";
        tendDate="NA";
      }else{
        tremarks=remarks;
        tendDate=endDate;
      }
      const params={
        journalID: journalid, 
        title:title,
        authorName:authorName, 
        journalName:journalName, 
        journalCategory:journalCategory,
        journalPrice:journalPrice,
        intlPrice:intlPrice,
        pages:pages,
        publisherName:publisherName,
        publishDate:publishDate, 
        journalStatus:journalStatus, 
        description:description, 
        endDate:tendDate, 
        remarks:tremarks,
        updatedBy:loggedUser.name, 
        updatedOn:tupdatedOn
      };
      //console.log("Params : ", params);
      await axios.patch(`${config.api.invokeUrl}/journal/${journalid}`, params,{
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          //console.log("response : ", response); 
          if(journalFile){
          await postPDFOnS3(journalid, journalFile);
          }
          if(posterFile){
            await postImageOnS3(journalid, posterFile);
          }
          toggle();
          fetchJournalDetails(event);
          enqueueSnackbar(journalid + " - Successfully Updated.",{variant : 'success'});
        window.scrollTo(0,0);
        setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      window.scrollTo(0,0);
      setLoaded(true);
    }
  }

  const generateSubscriberID=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    return accountNo;
}

  const handleJournalNew=async(event)=>{
    event.preventDefault();
    try{
    //  console.log("value : ", emailCodeEntered, emailCodeReceived);
    const validationResult=validateFields("Add");
    if(validationResult==="passed"){
      setLoaded(false);
      const {journalName, title,authorName, publishDate,journalStatus, description, journalCategory, 
        journalPrice, intlPrice, pages, publisherName }=pdfDetails;
      const journalid= await generateSubscriberID();
      const todayDate=await generateDate();
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const params={
        journalID: journalid, 
        title:title,
        authorName:authorName, 
        journalName:journalName, 
        journalCategory:journalCategory,
        journalPrice:journalPrice,
        intlPrice:intlPrice,
        pages:pages,
        publisherName:publisherName,
        publishDate:publishDate, 
        journalStatus:journalStatus, 
        description:description, 
        endDate:"NA", 
        remarks:"NA",
        updatedBy:loggedUser.name, 
        updatedOn:todayDate
      };
      //console.log("Params : ", params);
      await axios.post(`${config.api.invokeUrl}/journal/${journalid}`, params,{
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          await postPDFOnS3(journalid, journalFile);
          await postImageOnS3(journalid, posterFile);
          enqueueSnackbar(journalid + " - Successfully Registered.",{variant : 'success'});
        window.scrollTo(0,0);
        resetValues();
        setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          window.scrollTo(0,0);
          setLoaded(true);
        })
        setLoaded(true);
    }
      }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const postPDFOnS3 = async(pdfFileName,timageFile)=>{
    try{
        var keyName="pdfs/";
        var newFileName=pdfFileName+".pdf";      
      var file = timageFile;
      var blob = file.slice(0, file.size, 'application/pdf'); 
      const newFile = new File([blob], newFileName, {type: 'application/pdf'});
     const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const bucketName=config.s3.bucketName;
      const fileType="application/pdf";
      await axios.get(`${config.api.invokeUrl}/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}&keyName=${keyName}&fileType=${fileType}`,{
           headers:{
               Authorization: idToken,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           //console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"application/pdf"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
            //  setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
           // console.log("error1:", error);
           enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
     enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  
  }

  const postImageOnS3 = async(pdfFileName,timageFile)=>{
    try{
      var keyName="journal/posters/";
      var newFileName=pdfFileName+".jpeg";      
      const bucketName=config.s3.imgbucketName;
      //console.log("bucket name : ", bucketName);
      var file = timageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const fileType="image/jpeg";  
      await axios.get(`${config.api.invokeUrl}/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}&keyName=${keyName}&fileType=${fileType}`,{
          headers:{
               Authorization: idToken,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           //console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             //console.log("Res : ", res);
             if(res.status===200){

             }
        }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
            window.scrollTo(0,0);   
       })
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
      window.scrollTo(0,0);   
    }

  }


  const resetValues=()=>{
    const todayDate= generateDate();
    setPDFDetails({...pdfDetails,journalID:'', journalName:'', title:'Mr',authorName:'', publishDate:todayDate,journalStatus:'Active', description:'', endDate:todayDate, remarks:''});
  }

  const validateFields=(mode)=>{
    const {title,authorName, journalName,publishDate, description}=pdfDetails;      
        var returnValue="passed";
        if(mode==="Add" && !journalFile){
          enqueueSnackbar("Attach the Journal PDF file",{variant : 'info'});
        returnValue="failed";
      }else if(mode==="Add" && !posterFile){
        enqueueSnackbar("Attach the Poster Image file",{variant : 'info'});
      returnValue="failed";
    }else if(title===""){
      enqueueSnackbar("Title cannot be Empty",{variant : 'info'});
      returnValue="failed";
    }else  if(authorName===""){
      enqueueSnackbar("Author Name cannot be Empty",{variant : 'info'});
    returnValue="failed";
  }else  if(journalName===""){
    enqueueSnackbar("Journal Name cannot be Empty",{variant : 'info'});
  returnValue="failed";
  }else  if(publishDate===""){
    enqueueSnackbar("Publish Date cannot be Empty",{variant : 'info'});
  returnValue="failed";
  }else  if(description===""){
    enqueueSnackbar("Description cannot be Empty",{variant : 'info'});
  returnValue="failed";
  }else {
    returnValue="passed";
  }
  window.scrollTo(0,0);
  return returnValue;
  }

  return (
  <div>
    <Helmet>
      <title>Journal Upload - Admin Office - St. Mary's Church, Madurai</title>
      <meta name="description" content="Journal Upload - Admin Office - St. Mary's Church, Madurai" />
      <meta name="keywords" content="Journal Upload - Admin Office - St. Mary's Church, Madurai" /> 
    </Helmet>

    <Preloader show={loaded ? false : true} />

    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
    <div className="d-block mb-4 mb-md-0">
      <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
        <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
        <Breadcrumb.Item>Journal PDF Details</Breadcrumb.Item>
        <Breadcrumb.Item active>Registration Form</Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <div className="btn-toolbar mb-2 mb-md-0"> 
      <ButtonGroup>
      <ReactToPrint
    content={reactToPrintContent}
    documentTitle="student"
    trigger={reactToPrintTrigger}
  />
      </ButtonGroup>
    </div>
    </div>
     
    <div ref={componentRef}>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
      <Form>
        <Row>
              <Form.Group id="journalName">
                <Form.Label>Title</Form.Label>
                <Form.Control required type="text" placeholder="Journal Name" value={pdfDetails.journalName}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setPDFDetails({...pdfDetails, journalName:(event.target.value)})}}  
               />
              </Form.Group>
        </Row> 
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
              <Form.Label>Prefix</Form.Label>
               <Form.Select value={pdfDetails.title}
                disabled={viewOnly}
                onChange={(event)=>{setPDFDetails({...pdfDetails, title:event.target.value})}}
               >
                  <option value="Fr">Fr</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Dr">Dr</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={10} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Author</Form.Label>
                <Form.Control required type="text" placeholder="Author Name" value={pdfDetails.authorName}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setPDFDetails({...pdfDetails, authorName:(event.target.value)})}}  
               />
              </Form.Group>
            </Col>
        </Row> 
        <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Date Published</Form.Label>
                <Form.Control required type="date" value={pdfDetails.publishDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setPDFDetails({...pdfDetails, publishDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={5} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Publisher Name</Form.Label>
                <Form.Control required type="text" value={pdfDetails.publisherName}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setPDFDetails({...pdfDetails, publisherName:event.target.value})}}
                  />

              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select value={pdfDetails.journalStatus}
                disabled={viewOnly}
                onChange={(event)=>{setPDFDetails({...pdfDetails, journalStatus:event.target.value})}}
               >
                  <option value="Active">Active</option>
                  <option value="Archive">Archive</option>
                  <option value="In-Active">In-Active</option>
                 </Form.Select>

              </Form.Group>
            </Col>
        </Row>    
        <Row>
           <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Category</Form.Label>
                <Form.Select value={pdfDetails.journalCategory}
                disabled={viewOnly}
                onChange={(event)=>{setPDFDetails({...pdfDetails, journalCategory:event.target.value})}}
               >
                  <option value="Book Review">Book Review</option>
                  <option value="Editorial Section">Editorial Section</option>
                  <option value="Articles">Articles</option>
                  <option value="Full Issue">Full Issue</option>
                 </Form.Select>

              </Form.Group>
            </Col>
            <Col sm={2} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Price</Form.Label>
                <Form.Control required type="number" value={pdfDetails.journalPrice}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setPDFDetails({...pdfDetails, journalPrice:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Intl. Price</Form.Label>
                <Form.Control required type="number" value={pdfDetails.intlPrice}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setPDFDetails({...pdfDetails, intlPrice:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Pages</Form.Label>
                <Form.Control required type="number" value={pdfDetails.pages}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setPDFDetails({...pdfDetails, pages:event.target.value})}}
                  />
              </Form.Group>
            </Col>
        </Row>                  
        <Row>
              <Form.Group id="category">
                <Form.Label>Description</Form.Label>
                <Form.Control required as="textarea"  rows={3} value={pdfDetails.description}
                    disabled={viewOnly}
                    onChange={(event)=>{setPDFDetails({...pdfDetails, description:(event.target.value)})}}
                  />
              </Form.Group>
        </Row>
        {pdfDetails.journalStatus==="In-Active" && <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Date Removed</Form.Label>
                <Form.Control required type="date" value={pdfDetails.endDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setPDFDetails({...pdfDetails, endDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Remarks</Form.Label>
                <Form.Control required type="textarea"  rows={5} value={pdfDetails.remarks}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setPDFDetails({...pdfDetails, remarks:(event.target.value)})}}
                  />
              </Form.Group>
            </Col>
        </Row>  }
        {pdfFormType!=="View" ? <Row>
          <Col sm={6} className="mb-3">
          <JournalUploader fileName="test" setJournalFile={setJournalFile}/>
          </Col>
          <Col sm={6} className="mb-3">
          <PosterUploader fileName="test" setPosterFile={setPosterFile}/>
          </Col>
        </Row>:null}
                      
        {pdfFormType==="Edit"?<div className="mt-3 text-center">
          <Button variant="primary" type="submit" onClick={(event)=>{handleJournalEdit(event)}}>Update</Button>
          <Button variant="secondary" onClick={toggle}>Close</Button>
        </div>:pdfFormType==="New"?<div className="mt-3 text-center">
          <Button variant="primary" type="submit" onClick={(event)=>{handleJournalNew(event)}}>Save</Button>
          <Button variant="secondary" onClick={toggle}>Close</Button>
        </div>:null}
      </Form>
      </Card.Body>
      </Card>
      </div>
  </div>
  )
}
