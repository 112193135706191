import React from 'react';

const config = require('../../config.json');

export default function ImageDisplay(props) {
    return (
        <div className="card box" style={{margin:'10px'}} key={props.imageSrc}>
            {props.doctorName}
                        <img src={`${config.s3.imginvokeUrlGrave}${props.imageSrc}?t=${new Date().getTime()}`} alt="1" height="200" width="200" onError={props.handleImageError} /> <br />
                        {props.fullDate}
                        {/*<button onClick={(event)=>props.handleDeleteImage(event, props.imageSrc)}>Remove</button>*/}
                        <i className="fa fa-trash" aria-hidden="true" onClick={(event)=>props.handleDeleteImage(event, props.imageSrc)}></i>
        </div>
    )
}
