
export const RoutesLocal = {
    // pages
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },

    //auth
    Signin: { path: "/dashboard/sign-in" },
    Signup: { path: "/dashboard/sign-up" },
    ForgotPassword: { path: "/dashboard/forgot-password" },
    ResetPassword: { path: "/dashboard/reset-password" },
    NotFound: { path: "/dashboard/404" },
    ServerError: { path: "/dashboard/500" },
    InProgress: {path:"/dashboard/inprogress"},
    MyProfile: {path:"/dashboard/myprofile"},
    AdminForm: {path:"/dashboard/admin"},

    //registration
    NewRegistration:{path:"/registration/new"},

    //search
    Search: { path: "/familycard/search" },
    MemberSearch: { path: "/familymember/search" },
    SacrementSearch: { path: "/holysacrement/search" },
    GraveYardSearch: { path: "/graveyard/search" },

    //reports
    AnbiyamReports: { path: "/familycard/reports" },

    AccountMgmt:{ path:"/subscription/accounts"},
    ManageSubscribers:{ path:"/subscription/manage"},
    AddSubscriber:{ path:"/subscription/add"},

    //payemnts
    AddPayments:{ path:"/payments/add"},
    PaymentsReport:{ path:"/payments/report"},

    //insights
    CardInsights:{path:"/dashboard/insights/familycard"},
    MemberInsights:{path:"/dashboard/insights/familymember"},
    SacrementInsights:{path:"/dashboard/insights/familysacrement"},

    //Images
    ImageViewer: { path: "/imageviewer" },

    //PDF Journal
    ManagePDF:{path:"/journalpdf/manage"},
    AddPDF:{path:"/journalpdf/add"},

    //website
    SliderPath:{ path:"/website/slider"},

    //Images
    SliderViewer: { path: "/dashboard/slider-view" },
    IssuePoster: { path: "/dashboard/issue-poster" },

};