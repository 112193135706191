import React, { useEffect, useState, useContext } from 'react'
import { ProductContext } from '../../ProductProvider';

export default function PaymentMonthDisplay() {
    const { cardDetails} = useContext(ProductContext);
    const [displayYears, setDisplayYears] = useState([]);

    useEffect(()=>{
        processData();

    },[])

    const processData=()=>{

        //const regDate=new Date("2023-06-06");
        const regDate=new Date(cardDetails.registrationDate);
        //const paidDate= new Date("2023-09-09");
        const paidDate=new Date(cardDetails.lastSubscriptionDate);
        //const todayDAte=new Date().toDateString();
        const currentYear=new Date().getFullYear();
        const startYear=regDate.getFullYear();
        const tstartDate=startYear.toString()+"-01-01";
        var startDate=new Date(tstartDate);
        //const totalYears=todayDAte.getFullYear()-startYear;
        //console.log("year :", currentYear-startYear);
        //setYears(currentYear-startYear+1);
        const tyears=currentYear-startYear+1;
        var test=[];
        var tmain=[];
        const months=12;
        var y=1;
        var i=0;
        while( i < tyears){
            var x=0;
            while ( x < months){
                //console.log("Y :", y);
                var tdata={
                    no:0,
                    status:"none"
                };     
                //console.log("start Date :", startDate);
                //console.log("reg Date :", regDate);
                //console.log("paidDate  :", paidDate);
                if(startDate.getTime()<regDate.getTime()){
                    tdata.no=y;
                    tdata.status="NA";
                    tdata.times=startDate;
                }else if(startDate.getTime()>=regDate.getTime() && startDate.getTime()<=paidDate.getTime()){
                    tdata.no=y;
                    tdata.status="Paid";
                    tdata.times=startDate;
                }else{
                    tdata.no=y;
                    tdata.status="No";
                    tdata.times=startDate;
                }
                test.push(tdata);
                var tmonth=parseInt(startDate.getMonth())+2;
                if(tmonth<13){
                //console.log("month : ", tmonth);
                var ttmonth=tmonth.length>1?tmonth.toString:"0"+tmonth.toString();
                //console.log("updated Month : ", startDate.getFullYear().toString()+"-"+ttmonth.toString()+"-01");
                startDate=new Date(startDate.getFullYear().toString()+"-"+ttmonth.toString()+"-01");
                }
                x++;
                y++;
                //console.log("tdata ",tdata);
            }
            const ttdata={
                year:startDate.getFullYear(),
                months:test
            };
            //console.log("tdata ",ttdata);
            tmain.push(ttdata);
            test=[];
            const tyear=startDate.getFullYear()+1;
            //console.log("new year : ", tyear);
            startDate=new Date(tyear.toString()+"-01-01");
            i++;
        }
        //console.log("tmain :", tmain);
        setDisplayYears(tmain);
    }

  return (
    <div>
        <div className="row" style={{padding:"10px", right:800}}>
            <div className="card" style={{backgroundColor:"#8EB69B", color:"white", textAlign:"center", width:"10%"}}>Paid</div>
            <div className="card" style={{backgroundColor:"#F9DDC1", color:"black", textAlign:"center", width:"10%"}}>No</div>
            <div className="card" style={{backgroundColor:"#B4BCBC", color:"black", textAlign:"center", width:"10%"}}>NA</div>
        </div>
        <div class="container">
        {displayYears.length>0 && displayYears.map((item,index)=>
         <div className="row" key={index} style={{background:(index)%2===0?"#E1F8DC":"#FEF8DD", fontSize:"20px", top:'50%'}}>
            {item.year} 
        {item.months.map((value,i)=>
        <div className="col card" key={i} style={{backgroundColor:value.status==="Paid"?"#8EB69B":value.status==="No"?"#F9DDC1":"#B4BCBC", color:value.status==="Paid"?"white":"black", textAlign:"center"}}>
            <p>{new Date(value.times).toLocaleDateString('en-us', { month:"short"})}</p>
            {value.status==="Paid"?<i className="fa fa-check" aria-hidden="true"></i>
:null}
        </div>
        )}
        </div>            
        )}
        </div>
    </div>
  )
}
