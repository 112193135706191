import React, { useEffect, useContext, useState } from 'react';
//import { Col, Row, Card, Form } from 'react-bootstrap';
import { Col, Row, Card, Form } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Breadcrumb } from 'react-bootstrap';
import Preloader from "../../components/Preloader/Preloader";
import {Helmet} from 'react-helmet-async';
import { phoneCode, journalCategory } from "../../ProData";
import { useSnackbar } from 'notistack';

//import RegistrationForm from '../Registrations/RegistrationForm';
const config = require('../../config.json');

export default function MyProfile() {
   const { loggedUser, validateLogin} = useContext(ProductContext);
   const [loaded, setLoaded] = useState(false);
   const [viewOnly, setViewOnly] = useState(true);
   const [subscriberDetails, setsubscriberDetails] = useState({subscriberID:'', title:'Mr',subscriberName:'', areaCode:'+91',contactNo:'', emailID:'', houseName:'', addressStreet:'', addressCity:'', addressState:'',
   addressPincode:'', country:'', copies:'', categoryID:'',subStatus:'', regDate:'', startDate:'', endDate:''});
   const { enqueueSnackbar } = useSnackbar();

   //const [imageFile, setImageFile] = useState('');
  // const [profileImage,setProfileImage]=useState('https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg');
 
   useEffect(()=>{
     if(loggedUser.isAuthenticated){
       setViewOnly(true);
       fetchMyProfile();
     }else{
       validateLogin();
     }
   },[loggedUser])
 
   const fetchMyProfile=async()=>{
     setLoaded(false);
     try{
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
           const searchOption="SubscriberID";
           await axios.get(`${config.api.invokeUrl}/subscribers/globalsearch?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
             headers: {
               Authorization: idToken,
               'x-api-key':config.api.key
             }
           }).then(async(response) => {
            //console.log("response : ", response.data[0]);
             if(response.data.length>0)
             {
                 setsubscriberDetails(response.data[0]);
                 //setProfileDetails(response.data[0]);
                 enqueueSnackbar("Profile loaded Successfully!",{variant : 'success'});
               setLoaded(true);
             }
             setLoaded(true);
         }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
         setLoaded(true);
       })
 
     }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
     setLoaded(true);
 
   }
 }
 
  return (
    <div>
    <div>
    <Helmet>
      <title>My Profile - Admin Office - St. Mary's Church, Madurai</title>
      <meta name="description" content="My Profile - Admin Office - St. Mary's Church, Madurai" />
      <meta name="keywords" content="My Profile - Admin Office - St. Mary's Church, Madurai" /> 
    </Helmet>

         <Preloader show={loaded ? false : true} />
         <div className="d-block mb-4 mb-md-0 py-4">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item>Accounts</Breadcrumb.Item>
            <Breadcrumb.Item active>My Profile</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Profile</h4>

          <div >
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Select value={subscriberDetails.title}
                disabled={viewOnly}
                onChange={(event)=>{setsubscriberDetails({...subscriberDetails, title:event.target.value})}}
               >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Fr">Fr</option>
                  <option value="Other">other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={10} className="mb-3">
              <Form.Group id="fullname">
                <Form.Label>Full Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Full Name" value={subscriberDetails.subscriberName}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setsubscriberDetails({...subscriberDetails, subscriberName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="dateregistered">
                <Form.Label>Date Registered</Form.Label>
                <Form.Control required type="date" value={subscriberDetails.regDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, regDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Control required type="text" placeholder="Status" value={subscriberDetails.subStatus}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, subStatus:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="startdate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control required type="date" value={subscriberDetails.startDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, startDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>End Date</Form.Label>
                <Form.Control required type="date"  value={subscriberDetails.endDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, endDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>
                  
          <Row>
          <Col md={6} className="mb-3">
              <Form.Group id="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control required type="email" value={subscriberDetails.emailID}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col>
            <Form.Label>Contact Number</Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Select name="areacode"
                                  value={subscriberDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code+item.Country} value={item.Code}>{item.Code}</option>);
                              })}
                  </Form.Select>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="phone">
                <Form.Control required type="number" placeholder="Enter your contact number" 
                                disabled={viewOnly}
                                value={subscriberDetails.contactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, contactNo:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col> 
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="category">
                <Form.Label>Category</Form.Label>
                <Form.Select value={subscriberDetails.categoryID}
                        disabled={viewOnly}
                        onChange={(event)=>{setsubscriberDetails({...subscriberDetails, categoryID:event.target.value})}}>
                  {journalCategory.map(item => {
                    return (
                    <option key={item.id} value={item.category}>{item.category}</option>
                    );
                  })}
            </Form.Select>              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="copies">
                <Form.Label>No. of Copies</Form.Label>
                <Form.Control required type="number" value={subscriberDetails.copies}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, copies:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>House Name</Form.Label>
                <Form.Control required type="text" value={subscriberDetails.houseName}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, houseName:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" placeholder="Enter Full Address" value={subscriberDetails.addressStreet}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressStreet:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>City</Form.Label>
                <Form.Control required type="text" value={subscriberDetails.addressCity}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressCity:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" placeholder="Enter State" value={subscriberDetails.addressState}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group id="zip">
                <Form.Label>Pincode</Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={subscriberDetails.addressPincode}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="country">
                <Form.Label>Country</Form.Label>
                <Form.Control required type="text" placeholder="Enter Country" value={subscriberDetails.country}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, country:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            
          </Row>    
    
</Form>
</Card.Body>
</Card>
</div>

         </div>

    </div>
       {/*} <div class="midde_cont">
                  <div class="container-fluid">
                     <div class="row column_title">
                        <div class="col-md-12">
                           <div class="page_title">
                              <h2>Profile</h2>
                           </div>
                        </div>
                     </div>
                     <div class="row column1">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                           <div class="white_shd full margin_bottom_30">
                              <div class="full graph_head">
                                 <div class="heading1 margin_0">
                                    <h2>User profile</h2>
                                 </div>
                              </div>
                              <div class="full price_table padding_infor_info">
                                 <div class="row">
                                    <div class="col-lg-12">
                                       <div class="full dis_flex center_text">
                                          <div class="profile_img"><img width="180" class="rounded-circle" src="images/layout_img/user_img.jpg" alt="#" /></div>
                                          <div class="profile_contant">
                                             <div class="contact_inner">
                                                <h3>John Smith</h3>
                                                <p><strong>About: </strong>Frontend Developer</p>
                                                <ul class="list-unstyled">
                                                   <li><i class="fa fa-envelope-o"></i> : test@gmail.com</li>
                                                   <li><i class="fa fa-phone"></i> : 987 654 3210</li>
                                                </ul>
                                             </div>
                                             <div class="user_progress_bar">
                                                <div class="progress_bar">
                                                   <span class="skill" style={{width:"85%"}}>Web Applications <span class="info_valume">85%</span></span>                   
                                                   <div class="progress skill-bar ">
                                                      <div class="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style={{width: "85%"}}>
                                                      </div>
                                                   </div>
                                                   <span class="skill" style={{width:"78%"}}>Website Design <span class="info_valume">78%</span></span>   
                                                   <div class="progress skill-bar">
                                                      <div class="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style={{width: "78%"}}>
                                                      </div>
                                                   </div>
                                                   <span class="skill" style={{width:"47%"}}>Automation & Testing <span class="info_valume">47%</span></span>
                                                   <div class="progress skill-bar">
                                                      <div class="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="54" aria-valuemin="0" aria-valuemax="100" style={{width: "54%"}}>
                                                      </div>
                                                   </div>
                                                   <span class="skill" style={{width:"65%"}}>UI / UX <span class="info_valume">65%</span></span>
                                                   <div class="progress skill-bar">
                                                      <div class="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{width: "65%"}}>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="full inner_elements margin_top_30">
                                          <div class="tab_style2">
                                             <div class="tabbar">
                                                <nav>
                                                   <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                      <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#recent_activity" role="tab" aria-selected="true">Recent Activity</a>
                                                      <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#project_worked" role="tab" aria-selected="false">Projects Worked on</a>
                                                      <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#profile_section" role="tab" aria-selected="false">Profile</a>
                                                   </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                   <div class="tab-pane fade show active" id="recent_activity" role="tabpanel" aria-labelledby="nav-home-tab">
                                                      <div class="msg_list_main">
                                                         <ul class="msg_list">
                                                            <li>
                                                               <span><img src="images/layout_img/msg2.png" class="img-responsive" alt="#"/></span>
                                                               <span>
                                                               <span class="name_user">Taison Jack</span>
                                                               <span class="msg_user">Sed ut perspiciatis unde omnis.</span>
                                                               <span class="time_ago">12 min ago</span>
                                                               </span>
                                                            </li>
                                                            <li>
                                                               <span><img src="images/layout_img/msg3.png" class="img-responsive" alt="#"/></span>
                                                               <span>
                                                               <span class="name_user">Mike John</span>
                                                               <span class="msg_user">On the other hand, we denounce.</span>
                                                               <span class="time_ago">12 min ago</span>
                                                               </span>
                                                            </li>
                                                         </ul>
                                                      </div>
                                                   </div>
                                                   <div class="tab-pane fade" id="project_worked" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et 
                                                         quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos 
                                                         qui ratione voluptatem sequi nesciunt.
                                                      </p>
                                                   </div>
                                                   <div class="tab-pane fade" id="profile_section" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et 
                                                         quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos 
                                                         qui ratione voluptatem sequi nesciunt.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-2"></div>
                        </div>
                     </div>
                  </div>
               </div>*/}
    </div>
  )
}
